@import "../scss/utils.scss";

.buttonHorizontal {
  @include dims(100%);
  border-radius: 32px;
  position: relative;
  transition: 0.4s all ease;
  transform-origin: center;
  @extend .cursorPointer;

  &::after {
    content: "";
    @include position(absolute, 50%, 0, 0, 50%);
    @include dims(100%);
    border-radius: 64px;
    transform: translate(-50%, -50%);
    background: transparent;
    border: 1px solid #282828;
  }

  .btnInner {
    @extend .abscenter;

    &.withText {
      display: flex;
      align-items: flex-start;
    }

    .text {
      color: #282828;
      font-family: Moderat;
      font-size: 1.35rem;
      font-weight: 400;
      line-height: 100%;
      white-space: nowrap;

      @include devicesMax(mobile) {
        font-size: 1.25rem;
      }
    }
  }
}
