@import "assets/scss/utils.scss";
@import "assets/scss/variables.scss";

.privacyPolicyText {
  margin-left: 0.5rem;
  width: 90%;

  @include devicesMax(tablet) {
    margin-left: 1rem;
  }

  @include devicesMax(mobile) {
    margin-left: 1rem;
    width: 100%;
  }

  .small,
  .link {
    color: #3b3b3b;
    display: inline;
    font-family: Moderat;
    font-size: 1vw;
    font-weight: 400;
    line-height: 160%;

    @include devicesMax(tablet) {
      font-size: 1.5vw;
    }

    @include devicesMax(mobile) {
      font-size: 3.25vw;
    }
  }

  .link {
    text-decoration: underline;
  }
}
