@import "./utils.scss";

.brandCard {
  border: 1px solid #707070;
  border-radius: 12px;
  overflow: hidden;
  transition: 0.4s all ease;
  display: flex;
  flex-direction: column;
  @extend .cursorPointer;

  @include devicesMin(aboveTablet) {
    &:hover {
      border-bottom-left-radius: 32px;
      border-bottom-right-radius: 32px;

      .content {
        .cardButton {
          transform: rotate(7deg);
          background: #effffd;
        }
      }
    }
  }

  .imgW {
    @include dims(100%, 14vw);
    border-bottom: 1px solid #707070;
    margin-bottom: 1.5vw;
    position: relative;

    @include devicesMax(tablet) {
      @include dims(100%, 20vw);
      margin-bottom: 4vw;
    }

    @include devicesMax(mobile) {
      @include dims(100%, 50vw);
      margin-bottom: 8vw;
    }
  }

  .content {
    flex: 1;
    padding: 0 4vw;
    text-align: center;

    display: flex;
    align-items: center;
    flex-direction: column;

    .brandInfo {
      color: #282828;
      font-family: Moderat;
      font-weight: 500;
      font-size: 1rem;
      line-height: 100%;
      margin-bottom: 2vw;

      @include devicesMax(mobile) {
        margin-bottom: 7.5vw;
      }
    }

    .info {
      color: #282828;
      font-family: Moderat;
      font-weight: 400;
      font-size: 1.25vw;
      line-height: 160%;
      margin-bottom: 2vw;

      @include devicesMax(tablet) {
        font-size: 2vw;
        margin-bottom: 4vw;
      }

      @include devicesMax(mobile) {
        font-size: 4.5vw;
        margin-bottom: 8vw;
      }
    }

    .cardButton {
      border-radius: 32px;
      margin-bottom: 2.5vw;
      margin-top: auto;
      padding: 1em 2em;
      position: relative;
      transition: 0.4s all ease;
      transform-origin: center;
      display: flex;
      align-items: center;
      justify-content: center;
      @extend .cursorPointer;

      @include devicesMax(tablet) {
        margin-bottom: 3.5vw;
      }

      @include devicesMax(mobile) {
        margin-bottom: 7.5vw;
      }

      &::after {
        content: "";
        @include position(absolute, 50%, 0, 0, 50%);
        @include dims(100%);
        border-radius: 64px;
        transform: translate(-50%, -50%);
        background: transparent;
        border: 1px solid #282828;
      }

      .text {
        color: #282828;
        font-family: Moderat;
        font-size: 1.35vw;
        font-weight: 400;
        line-height: 100%;
        white-space: nowrap;

        @include devicesMax(tablet) {
          font-size: 1.75vw;
        }

        @include devicesMax(mobile) {
          font-size: 1.25rem;
        }
      }
    }
  }
}
