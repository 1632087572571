@import "../scss/utils.scss";

.feedback {
  @include position(fixed, auto, 1vw, 1vw, auto);
  @include dims(40vw, auto);
  background: #bdf98a;
  border-radius: 16px;
  transition: 0.4s all ease;
  z-index: z-index("feedback");
  @extend .hide;

  display: flex;
  align-items: center;
  justify-content: center;

  @include devicesMax(tablet) {
    @include dims(60vw, auto);
  }

  @include devicesMax(mobile) {
    @include position(fixed, auto, 1vw, 1%, 1vw);
    @include dims(98vw, auto);
  }

  &::after {
    content: "";
    @include position(absolute, 50%, auto, auto, 50%);
    @include dims(98.5%, 95%);
    border-radius: 12px;
    transform: translate(-50%, -50%);
    border: 1px solid #282828;

    @include devicesMax(mobile) {
      border-radius: 14px;
    }
  }

  &.visible {
    @extend .show;
  }

  .close {
    @include position(absolute, 0.75rem, 0.75rem, auto, auto);
    @include dims(16px, auto);
    display: inline-block;
    z-index: z-index("modalClose");
    @extend .cursorPointer;

    @include devicesMax(mobile) {
      @include position(absolute, 1rem, 1rem, auto, auto);
      @include dims(10px, auto);
    }
  }

  .text {
    @include dims(50%, 100%);
    flex: 1;
    background: #f9fff3;
    border-radius: 12px;
    font-family: Moderat;
    font-size: 1.5vw;
    line-height: 160%;
    margin: 1.5vw 2vw;
    padding: 2vw;

    @include devicesMax(tablet) {
      font-size: 2.25vw;
      margin: 2vw 3vw;
      padding: 3vw;
    }

    @include devicesMax(mobile) {
      border-radius: 10px;
      font-size: 4vw;
      margin: 2vw 2.25vw;
    }
  }

  .charW {
    @include dims(10vw);
  }
}
