@import "assets/scss/utils.scss";
@import "assets/scss/variables.scss";

.header {
  @include dims(auto, $headerHeight);
  border-bottom: 1px solid #adadad;
  border-left: 1px solid #adadad;
  border-right: 1px solid #adadad;
  margin: 0 calc($frameWidth - 1px);
  position: relative;
  z-index: z-index("header");

  display: flex;
  align-items: center;

  @include devicesMax(tablet) {
    @include position(fixed, 0, 0, auto, 0);
    @include dims(auto, $headerHeightTablet);
    background: #fff;
    margin: 0 calc($frameWidthTablet - 1px);
  }

  @include devicesMax(mobile) {
    @include dims(auto, $headerHeightMobile);
    border-left: none;
    border-right: none;
    margin: 0;
  }

  .logoW {
    @include dims(14vw, auto);
    @extend .cursorPointer;
    margin: 0 2vw;
    z-index: z-index("logo");

    @include devicesMax(tablet) {
      @include dims(24vw, auto);
    }

    @include devicesMax(mobile) {
      @include dims(40vw, auto);
      margin: 0 4vw;
    }
  }

  .btnLanguage {
    @include dims(7vw, 1.75vw);
    margin-left: auto;
    margin-right: 2vw;
    z-index: z-index("lang");

    @include devicesMax(tablet) {
      @include dims(9vw, 2.25vw);
    }

    @include devicesMax(mobile) {
      display: none;
    }
  }

  .zula {
    @include dims(calc($headerHeight + $frameWidth), calc($headerHeight - 1px));
    @extend .cursorPointer;
    background: #effffd;
    border-left: 1px solid #adadad;
    flex-shrink: 0;
    transition: 0.4s all ease;
    z-index: z-index("hamburger");

    display: flex;
    align-items: center;
    justify-content: center;

    @include devicesMax(tablet) {
      @include dims(calc($headerHeightTablet + $frameWidthTablet), calc($headerHeightTablet - 1px));
    }

    @include devicesMax(mobile) {
      @include dims(calc($headerHeightMobile + $frameWidthMobile), calc($headerHeightMobile - 1px));
      margin-left: auto;
    }

    &.open {
      border-left: 1px solid #effffd;
    }

    @include devicesMin(aboveTablet) {
      &:hover {
        .lines {
          .line {
            &.horizontal1 {
              transform: translate3d(50%, 0, 0);
            }

            &.horizontal3 {
              transform: translate3d(0, 0, 0);
            }
          }
        }
      }
    }

    .lines {
      @include dims(1.5vw);
      flex-shrink: 0;
      overflow: hidden;
      padding: 0.25vw 0;
      position: relative;

      display: flex;
      align-items: flex-start;
      flex-direction: column;
      justify-content: space-between;

      @include devicesMax(tablet) {
        @include dims(3vw);
      }

      @include devicesMax(mobile) {
        @include dims(5.5vw, 5vw);
      }

      .line {
        @include dims(100%, 3px);
        background: #3b3b3b;
        transition: 0.4s all ease-in-out;

        &.horizontal3 {
          transform: translate3d(-50%, 0, 0);
        }
      }

      .cross1W {
        @include position(absolute, 50%, auto, auto, 50%);
        @include dims(100%, 3px);
        transform: rotate(45deg) translate3d(-50%, -40%, 0);
        transform-origin: 0 50%;

        .cross1 {
          @include dims(100%);
          background: #3b3b3b;
          transform: translate3d(130%, 0, 0);
          transition: 0.2s all ease-in-out;
        }
      }

      .cross2W {
        @include position(absolute, 50%, auto, auto, 50%);
        @include dims(100%, 3px);
        transform: rotate(-45deg) translate3d(-50%, -40%, 0);
        transform-origin: 0 100%;

        .cross2 {
          @include dims(100%);
          background: #3b3b3b;
          transform: translate3d(130%, 0, 0);
          transition: 0.2s all ease-in-out;
        }
      }
    }

    &.open {
      .lines {
        .line {
          &.horizontal1,
          &.horizontal2,
          &.horizontal3 {
            transform: translate3d(-105%, 0, 0);
            transition-delay: 0s;
          }
        }

        .cross1W {
          .cross1 {
            transform: translate3d(-10%, 50%, 0);
            transition-delay: 0.4s;
          }
        }

        .cross2W {
          .cross2 {
            transform: translate3d(0, -50%, 0);
            transition-delay: 0.4s;
          }
        }
      }
    }
  }
}
