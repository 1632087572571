@import "assets/scss/utils.scss";
@import "assets/scss/variables.scss";

.career {
  .intro {
    padding: 3vw 0 5vw 0;
    position: relative;
    @extend .horizontalBorders;

    display: flex;
    flex-direction: column;
    align-items: center;

    @include devicesMax(tablet) {
      padding: 5vw 0;
    }

    @include devicesMax(mobile) {
      padding: 15vw 0 10vw 0;
    }

    .bg {
      @include position(absolute, auto, 0, -50%, 50%);
      @include dims(150%, 50%);
      background: #fff;
      transform: rotate(4deg) translateX(-50%);
      z-index: -1;
    }

    .title {
      color: #282828;
      display: block;
      font-family: ModeratExtended;
      font-weight: 500;
      font-size: 12vw;
      line-height: 120%;
      margin-bottom: 5vw;

      @include devicesMax(tablet) {
        margin-bottom: 7.5vw;
      }

      @include devicesMax(mobile) {
        font-size: 16vw;
        text-align: center;
      }

      .italic {
        font-style: italic;

        @include devicesMax(mobile) {
          display: block;
        }
      }
    }

    .bottom {
      display: flex;
      align-items: flex-start;

      @include devicesMax(mobile) {
        flex-direction: column;
      }

      .imgW {
        @include dims(47.5vw, auto);
        flex-shrink: 0;
        margin-top: 3vw;

        @include devicesMax(tablet) {
          @include dims(50vw, auto);
        }

        @include devicesMax(mobile) {
          @include dims(100%, auto);
        }
      }

      .descW {
        margin-left: 5vw;

        @include devicesMax(tablet) {
          margin-left: 10vw;
        }

        @include devicesMax(mobile) {
          margin: 15vw 10vw;
        }

        .desc {
          @include dims(23vw, auto);
          color: #282828;
          font-family: Moderat;
          font-weight: 400;
          font-size: 1.1vw;
          line-height: 180%;

          @include devicesMax(tablet) {
            @include dims(28vw, auto);
            font-size: 1.5vw;
          }

          @include devicesMax(mobile) {
            @include dims(100%, auto);
            font-size: 4.5vw;
            text-align: center;
          }
        }

        .guideW {
          @include dims(26vw, auto);
          margin-top: 10vw;
          position: relative;

          display: flex;

          .iconW {
            @include position(absolute, 30%, 105%, auto, auto);
          }

          .text {
            color: #282828;
            font-family: Moderat;
            font-weight: 400;
            font-size: 1vw;
            line-height: 140%;
          }
        }
      }
    }
  }

  // .jobList {
  //   border-top: 1px solid #efefff;
  //   position: relative;
  //   z-index: 5;

  //   display: grid;
  //   grid-template-rows: auto;

  //   .job {
  //     border-bottom: 1px solid #efefff;
  //     padding: 3vw 10vw;
  //     @extend .cursorPointer;

  //     display: flex;
  //     align-items: center;
  //     justify-content: space-between;

  //     &:hover {
  //       background: #efefff;
  //     }

  //     .jobTitle {
  //       color: #282828;
  //       font-family: Moderat;
  //       font-weight: 400;
  //       font-size: 2vw;
  //       line-height: 100%;
  //     }

  //     .applyBtnW {
  //       @include dims(15vw, 3.5vw);
  //       color: #282828;
  //       font-family: Moderat;
  //       font-weight: 400;
  //       font-size: 1vw;
  //       line-height: 100%;
  //     }
  //   }
  // }

  .careerForm {
    margin: 0 calc($frameWidth);

    @include devicesMax(mobile) {
      margin: 0;
    }

    .form {
      $fontSize: 1.25rem;
      $paddingLeft: 1.25rem;
      margin: 5vw 8vw 7vw 8vw;
      display: grid;
      grid-template-columns: repeat(2, 1fr);

      @include devicesMax(tablet) {
        margin: 5vw 12vw 7vw 12vw;
        grid-template-columns: repeat(1, auto);
      }

      @include devicesMax(mobile) {
        margin: 0;
      }

      .error {
        @include position(absolute, 100%, auto, auto, auto);
        color: #3b3b3b;
        font-family: Moderat;
        font-size: 1rem;
        font-weight: 400;
        line-height: 160%;
        margin: 2vw 0;
      }

      .col {
        margin: 0 4vw;

        .row {
          display: flex;
          gap: 1rem;

          @include devicesMax(mobile) {
            flex-direction: column;
            gap: 0;
          }
        }
      }

      .inputW {
        @include dims(100%, 3.5vw);
        border: 1px solid #adadad;
        border-radius: 32px;
        position: relative;
        margin-bottom: 2vw;

        @include devicesMax(tablet) {
          @include dims(100%, 6vw);
        }

        @include devicesMax(mobile) {
          @include dims(100%, 12vw);
          margin-bottom: 4vw;
        }

        &.name {
          .small {
            @include position(absolute, auto, auto, 120%, auto);
            color: #bebebe;
            font-family: Moderat;
            font-size: 1rem;
            font-weight: 400;
            line-height: 160%;
            padding-left: $paddingLeft;

            @include devicesMax(mobile) {
              @include position(absolute, auto, auto, 120%, 50%);
              padding-left: 0;
              transform: translateX(-50%);
            }
          }
        }

        &.file {
          @extend .cursorPointer;

          .input {
            opacity: 0;
          }

          @include devicesMax(tablet) {
            margin-top: 5vw;
          }

          @include devicesMax(mobile) {
            margin-top: 10vw;
          }

          .small {
            @include position(absolute, 120%, auto, auto, auto);
            @include dims(22vw, auto);
            color: #bebebe;
            font-family: Moderat;
            font-size: 1rem;
            font-weight: 400;
            line-height: 160%;
            padding-left: $paddingLeft;

            @include devicesMax(tablet) {
              @include dims(100%, auto);
            }

            @include devicesMax(mobile) {
              font-size: 0.75rem;
            }
          }

          .iconW {
            @include dims(auto, 1.25vw);
            margin-right: $paddingLeft;

            @include devicesMax(tablet) {
              @include dims(auto, 2.25vw);
            }

            @include devicesMax(mobile) {
              @include dims(auto, 5vw);
            }
          }
        }

        .label {
          @include position(absolute, 0, 0, 0, 0);
          @extend .flexstartH;
          color: #282828;
          font-family: Moderat;
          font-size: $fontSize;
          font-weight: 400;
          line-height: 120%;
          opacity: 1;
          padding-left: $paddingLeft;
          transition: 0.4s all ease;

          &.hidden {
            transition: 0s;
            @extend .hide;
          }
        }

        .input {
          @include position(absolute, 0, 0, 0, 0);
          @extend .flexstartH;
          border-radius: 32px;
          color: #282828;
          font-family: Moderat;
          font-size: $fontSize;
          font-weight: 400;
          line-height: 120%;
          margin: 0;
          padding: 0;
          padding-left: $paddingLeft;
          background: none;
          border: none;
          outline: none;
        }

        .iconW {
          @include position(absolute, 50%, 0, auto, auto);
          @include dims(2vw, auto);
          transform: translateY(-50%);
        }
      }

      .textareaW {
        @include dims(100%, auto);
        border-bottom: 1px solid #adadad;
        margin-bottom: 1.5vw;
        margin-top: 1.5vw;

        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;

        @include devicesMax(tablet) {
          margin-bottom: 3vw;
        }

        @include devicesMax(mobile) {
          margin-bottom: 6vw;
          margin-top: 5vw;
        }

        .label {
          @include dims(100%, auto);
          @extend .flexstartH;
          color: #3b3b3b;
          font-family: Moderat;
          font-size: $fontSize;
          font-weight: 400;
          line-height: 120%;
          margin-bottom: 0.5vw;
          padding-left: $paddingLeft;

          @include devicesMax(mobile) {
            margin-bottom: 2vw;
          }
        }

        .textarea {
          @include dims(100%, 8vw);
          color: #3b3b3b;
          font-family: Moderat;
          font-size: 1.25rem;
          font-weight: 400;
          line-height: 120%;
          margin: 0;
          padding: 0;
          background: none;
          border: none;
          outline: none;
          resize: none;

          @include devicesMax(tablet) {
            @include dims(100%, 12vw);
          }

          @include devicesMax(mobile) {
            @include dims(100%, 30vw);
          }
        }
      }

      .confirmation {
        $boxDims: 20px;
        @extend .cursorPointer;
        margin-top: 6vw;

        display: flex;
        align-items: flex-start;
        justify-content: flex-start;

        @include devicesMax(mobile) {
          margin-top: 20vw;
        }

        .checkbox {
          @include dims($boxDims);
          border: 1px solid #282828;
          border-radius: 4px;
          flex-shrink: 0;
          margin-top: 0.25vw;
          position: relative;

          .inner {
            @extend .abscenter;
            @include dims(calc($boxDims - 6px));
            background: #282828;
            border-radius: 2px;
            transition: 0.3s all ease;
            @extend .hide;

            &.enabled {
              @extend .show;
            }
          }
        }

        .text {
          margin-left: 0.5rem;
          width: 90%;

          @include devicesMax(tablet) {
            margin-left: 1rem;
            width: 100%;
          }

          .small,
          .link {
            color: #3b3b3b;
            display: inline;
            font-family: Moderat;
            font-size: 1vw;
            font-weight: 400;
            line-height: 160%;

            @include devicesMax(tablet) {
              font-size: 1.35vw;
            }

            @include devicesMax(mobile) {
              font-size: 3.25vw;
            }
          }

          .link {
            display: inline-block;
            text-decoration: underline;
          }
        }
      }

      .sendBtn {
        @include dims(20vw, 3.75vw);
        border: 1px solid #282828;
        border-radius: 32px;
        color: #282828;
        display: block;
        font-family: Moderat;
        font-size: 1.5vw;
        font-weight: 400;
        line-height: 100%;
        margin-top: 4vw;
        opacity: 0.3;
        pointer-events: none;
        transition: 0.4s all ease;
        transform-origin: center;
        @extend .cursorPointer;

        &:hover {
          background: #fef8a6;
          transform: rotate(-9deg);
        }

        &.enabled {
          opacity: 1;
          pointer-events: auto;
        }

        @include devicesMax(tablet) {
          @include dims(25vw, 6vw);
          font-size: 2vw;
          margin: 5vw auto 0 auto;
        }

        @include devicesMax(mobile) {
          @include dims(60vw, 12vw);
          font-size: 5vw;
          margin: 15vw auto;
        }
      }
    }

    .stickers {
      margin-bottom: auto;
      margin-top: 3vw;

      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: flex-end;

      .sticker {
        border-radius: 44px;
        display: inline-block;
        white-space: nowrap;

        display: flex;
        align-items: center;

        &.stickerAsk {
          background: #efefff;
          color: #282828;
          font-family: Moderat;
          font-weight: 400;
          font-size: 6.5vw;
          line-height: 100%;
          padding: 2rem 4rem;
          transform: rotate(10deg);
        }

        &.stickerContact {
          background: #effffd;
          color: #282828;
          font-family: Moderat;
          font-weight: 400;
          font-size: 5vw;
          line-height: 100%;
          padding: 1.5rem 4rem;
          transform: rotate(-7deg);
        }

        .text {
          transform: translateY(-5%);
        }

        .imgW {
          @include dims(6vw);
          display: inline-block;
          margin-left: 2vw;
        }
      }
    }
  }
}
