@import "assets/scss/utils.scss";
@import "assets/scss/variables.scss";

.navMenu {
  @include position(fixed, 0, 0, 0, 0);
  @include dims(100vw, 100vh);
  background: #effffd;
  padding-top: $headerHeight;
  pointer-events: none;
  transition: 0.6s all ease-in-out;
  transition-delay: 0;
  z-index: z-index("menu");
  @extend .hide;

  display: flex;
  align-items: center;
  justify-content: flex-start;

  @include devicesMax(tablet) {
    @include dims(100vw, 100%);
    align-items: center;
    padding-top: $headerHeightTablet;
  }

  @include devicesMax(mobile) {
    padding-top: $headerHeightMobile;
  }

  &::after {
    content: "";
    @include position(absolute, $headerHeight, auto, auto, 50%);
    @include dims(96vw, 1px);
    background: #b9ddd8;
    transform: translateX(-50%);

    @include devicesMax(tablet) {
      @include position(absolute, $headerHeightTablet, auto, auto, 50%);
    }

    @include devicesMax(mobile) {
      @include position(absolute, $headerHeightMobile, auto, auto, 50%);
    }
  }

  &.open {
    // transition-delay: 1s;
    pointer-events: auto;
    @extend .show;
  }

  .thumbnails {
    @include position(absolute, 0, auto, 0, 0);
    @include dims(60%, 100%);

    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 100;

    .wr {
      @include dims(100%);
      position: relative;
    }

    @include devicesMax(tablet) {
      display: none;
    }
  }

  .nav {
    margin-top: -8vw;
    margin-left: 62vw;

    @include devicesMax(tablet) {
      margin: 0 auto $headerHeightTablet auto;
    }

    @include devicesMax(mobile) {
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
    }

    .navItems {
      display: flex;
      flex-direction: column;

      .navItem {
        display: block;
        margin-bottom: 1vw;
        margin-right: auto;
        padding: 1vw 0;
        position: relative;

        @include devicesMax(tablet) {
          padding: 2vw 0;
          text-align: center;
          margin-left: auto;
        }

        .animatedSvg {
          @include position(absolute, 50%, auto, auto, 50%);
          height: 100%;
          transform: translate(-50%, -50%);
          opacity: 0;
          transition: stroke-dashoffset 2s cubic-bezier(0.43, 0.2, 0.02, 1),
            opacity 0.6s cubic-bezier(0.43, 0.2, 0.02, 1);
        }

        .link {
          color: #282828;
          display: inline-block;
          font-family: Moderat;
          font-weight: 400;
          font-size: 2vw;
          line-height: 100%;
          position: relative;

          @include devicesMax(tablet) {
            font-size: 4vw;
            margin-left: 0;
            line-height: 140%;
          }

          @include devicesMax(mobile) {
            font-size: 7vw;
          }

          .index {
            @include position(absolute, 0, auto, auto, -20px);
            @include dims(0.75rem, 1rem);
            border: 1px solid #bfccca;
            border-radius: 50%;
            color: #bfccca;
            font-size: 0.85rem;
            text-align: center;

            display: flex;
            align-items: center;
            justify-content: center;

            @include devicesMax(tablet) {
              display: none;
            }
          }
        }
      }
    }

    .btnLanguage {
      display: none;

      @include devicesMax(mobile) {
        @include dims(28vw, 8vw);
        display: inline-block;
        margin: 10vw auto 0 auto;
      }
    }
  }

  .bottom {
    @include position(absolute, auto, 0, 0, 0);
    @include dims(auto, $headerHeight);
    border-top: 1px solid #b9ddd8;
    margin: auto 2vw;
    display: flex;
    align-items: center;

    @include devicesMax(tablet) {
      @include dims(auto, calc($headerHeightTablet + 2vw));
    }

    @include devicesMax(mobile) {
      @include dims(auto, $headerHeightMobile);
    }

    .link {
      color: #282828;
      display: block;
      font-family: Moderat;
      font-weight: 400;
      font-size: 1.25vw;
      line-height: 100%;
      margin-left: 60vw;
      display: flex;
      align-items: flex-start;

      @include devicesMax(tablet) {
        font-size: 2.5vw;
        margin-left: 2vw;
      }

      @include devicesMax(mobile) {
        font-size: 4.5vw;
      }

      .iconW {
        @include dims(auto, 100%);
        margin-left: 0.5rem;

        @include devicesMax(mobile) {
          display: none;
        }
      }
    }

    .social {
      margin-left: auto;
      margin-right: 2vw;

      display: flex;
      align-items: center;

      .socialLink {
        @extend .cursorPointer;
        margin-left: 1.5vw;
        display: flex;
        align-items: center;

        @include devicesMax(tablet) {
          margin-left: 6vw;
        }
      }
    }
  }
}
