@import "assets/scss/utils.scss";
@import "assets/scss/variables.scss";

.contact {
  @extend .horizontalBorders;

  .intro {
    margin: 0 calc($frameWidth);
    padding: 6vw 0 10vw 0;

    // background img
    background-image: url("../img/map.jpg");
    background-position: 100% 0;
    background-repeat: no-repeat;
    background-size: 50vw;

    @include devicesMax(tablet) {
      background-size: 60vw;
      margin: 0;
    }

    @include devicesMax(mobile) {
      padding: 15vw 0 5vw 0;
      background: none;
    }

    .titleW {
      margin: 0 8vw 5vw 8vw;

      @include devicesMax(mobile) {
        margin: 0 8vw 20vw 8vw;
      }

      .title {
        color: #282828;
        line-height: 110%;

        &:nth-child(1) {
          font-family: Moderat;
          font-weight: 300;
          font-size: 4.5vw;
          font-style: italic;

          @include devicesMax(mobile) {
            font-size: 12vw;
            margin-bottom: 2vw;
            text-align: center;
          }
        }

        &:nth-child(2) {
          font-family: ModeratExtended;
          font-weight: 500;
          font-size: 7.5vw;

          br {
            display: none;
          }

          @include devicesMax(mobile) {
            font-size: 17vw;
            margin-bottom: 10vw;
            text-align: center;

            br {
              display: block;
            }
          }
        }
      }
    }

    .contactInfoW {
      @include dims(25vw, auto);
      margin: 0 8vw 2vw 8vw;

      display: flex;
      flex-direction: column;

      @include devicesMax(tablet) {
        @include dims(40vw, auto);
      }

      @include devicesMax(mobile) {
        @include dims(100%, auto);
        margin: 0;
      }

      .title {
        color: #282828;
        font-family: ModeratExtended;
        font-weight: 500;
        font-size: 3.5vw;
        line-height: 100%;
        margin-bottom: 2vw;
      }

      .contactInfo {
        @include devicesMax(mobile) {
          padding: 0 4vw;
        }

        .part {
          &.emailPhone {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .item {
              text-decoration: underline;
            }

            @include devicesMax(mobile) {
              align-items: center;
              flex-direction: column;
              justify-content: center;
            }
          }

          .itemW {
            margin-bottom: 2vw;
            display: flex;
            flex-direction: column;

            @include devicesMax(mobile) {
              margin-bottom: 10vw;
            }

            .label {
              color: #b9ddd8;
              font-family: Moderat;
              font-weight: 500;
              font-size: 0.75rem;
              line-height: 100%;
              margin-bottom: 0.5vw;

              @include devicesMax(mobile) {
                margin-bottom: 2vw;
                text-align: center;
              }
            }

            .item {
              color: #282828;
              font-family: Moderat;
              font-weight: 400;
              font-size: 1.25vw;
              line-height: 100%;

              &.address {
                border-radius: 16px;
                line-height: 150%;

                display: flex;
                flex-direction: column;
              }

              @include devicesMax(tablet) {
                font-size: 2vw;
              }

              @include devicesMax(mobile) {
                font-size: 5.5vw;
                text-align: center;
              }
            }
          }
        }
      }

      .social {
        margin-top: auto;
        margin-left: auto;

        .link {
          &:nth-child(2) {
            margin-left: 1.5vw;
          }
        }
      }
    }

    .btnW {
      @include position(absolute, 24vw, 22vw, auto, auto);
      @include dims(12vw);

      @include devicesMax(tablet) {
        @include position(absolute, 28vw, 20vw, auto, auto);
        @include dims(16vw);
      }

      @include devicesMax(mobile) {
        @include position(relative, auto, auto, auto, auto);
        @include dims(45vw);
        display: block;
        margin: 5vw auto;
      }
    }
  }

  .contactForm {
    margin: 0 calc($frameWidth);

    display: grid;
    grid-template-columns: repeat(2, 1fr);

    @include devicesMax(mobile) {
      margin: 0 4vw;
      display: grid;
      grid-template-columns: repeat(1, auto);
      grid-template-rows: 1.25fr 2fr;
    }

    .form {
      $fontSize: 1.25rem;
      $fontSizeMobile: 1rem;
      $paddingLeft: 1.25rem;

      @include dims(35vw, auto);
      margin: 5vw 8vw 7vw 8vw;

      @include devicesMax(tablet) {
        @include dims(45vw, auto);
        margin: 5vw 3vw 7vw 2vw;
      }

      @include devicesMax(mobile) {
        @include dims(100%, auto);
        margin: 0;
        order: 2;
        padding-bottom: 20vw;
      }

      .error {
        @include position(absolute, 100%, auto, auto, auto);
        color: #3b3b3b;
        font-family: Moderat;
        font-size: 1rem;
        font-weight: 400;
        line-height: 160%;
        margin: 2vw 0;
      }

      .row {
        display: flex;
        gap: 1rem;

        @include devicesMax(mobile) {
          flex-direction: column;
          gap: 0;
        }
      }

      .inputW {
        @include dims(100%, 3.5vw);
        border: 1px solid #adadad;
        border-radius: 32px;
        position: relative;
        margin-bottom: 2vw;

        @include devicesMax(tablet) {
          @include dims(100%, 5.5vw);
        }

        @include devicesMax(mobile) {
          @include dims(100%, 14vw);
          margin-bottom: 4vw;
        }

        .smallTop {
          @include position(absolute, auto, auto, 120%, auto);
          color: #b9ddd8;
          font-family: Moderat;
          font-size: 1rem;
          font-weight: 400;
          line-height: 120%;
          padding-left: $paddingLeft;
          white-space: nowrap;

          @include devicesMax(mobile) {
            @include position(absolute, auto, auto, 110%, 50%);
            font-size: 12px;
            margin-bottom: 2vw;
            padding-left: 0;
            transform: translateX(-50%);
          }
        }

        .label {
          @include position(absolute, 0, 0, 0, 0);
          @extend .flexstartH;
          color: #282828;
          font-family: Moderat;
          font-size: $fontSize;
          font-weight: 400;
          line-height: 120%;
          opacity: 1;
          padding-left: $paddingLeft;
          transition: 0.4s all ease;

          &.hidden {
            transition: 0s;
            @extend .hide;
          }
        }

        .input {
          @include position(absolute, 0, 0, 0, 0);
          @extend .flexstartH;
          border-radius: 32px;
          color: #282828;
          font-family: Moderat;
          font-size: $fontSize;
          font-weight: 400;
          line-height: 120%;
          margin: 0;
          padding: 0;
          padding-left: $paddingLeft;
          background: none;
          border: none;
          outline: none;
        }

        .iconW {
          @include position(absolute, 50%, 0, auto, auto);
          @include dims(2vw, auto);
          transform: translateY(-50%);
        }
      }

      .textareaW {
        @include dims(100%, 8vw);
        border-bottom: 1px solid #adadad;
        margin-bottom: 1.5vw;
        margin-top: 1.5vw;

        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;

        @include devicesMax(tablet) {
          @include dims(100%, 14vw);
          margin-bottom: 3vw;
        }

        @include devicesMax(mobile) {
          @include dims(100%, 35vw);
          margin-bottom: 6vw;
          margin-top: 5vw;
        }

        .label {
          @include dims(100%, auto);
          @extend .flexstartH;
          color: #3b3b3b;
          font-family: Moderat;
          font-size: $fontSize;
          font-weight: 400;
          line-height: 120%;
          margin-bottom: 0.5vw;
          padding-left: $paddingLeft;

          @include devicesMax(mobile) {
            font-size: $fontSizeMobile;
            margin-bottom: 2vw;
          }
        }

        .textarea {
          @include dims(100%, 5vw);
          color: #3b3b3b;
          font-family: Moderat;
          font-size: 1.25rem;
          font-weight: 400;
          line-height: 120%;
          margin: 0;
          padding: 0;
          background: none;
          border: none;
          outline: none;
          resize: none;

          @include devicesMax(tablet) {
            @include dims(100%, 10vw);
          }

          @include devicesMax(mobile) {
            @include dims(100%, 30vw);
          }
        }
      }

      .confirmation {
        $boxDims: 20px;
        @extend .cursorPointer;

        display: flex;
        align-items: flex-start;
        justify-content: flex-start;

        .checkbox {
          @include dims($boxDims);
          border: 1px solid #282828;
          border-radius: 4px;
          flex-shrink: 0;
          margin-top: 0.25vw;
          position: relative;

          .inner {
            @extend .abscenter;
            @include dims(calc($boxDims - 6px));
            background: #282828;
            border-radius: 2px;
            transition: 0.3s all ease;
            @extend .hide;

            &.enabled {
              @extend .show;
            }
          }
        }

        // .text {
        //   margin-left: 0.5rem;
        //   width: 90%;

        //   @include devicesMax(tablet) {
        //     margin-left: 1rem;
        //   }

        //   @include devicesMax(mobile) {
        //     margin-left: 1rem;
        //     width: 100%;
        //   }

        //   .small,
        //   .link {
        //     color: #3b3b3b;
        //     display: inline;
        //     font-family: Moderat;
        //     font-size: 1vw;
        //     font-weight: 400;
        //     line-height: 160%;

        //     @include devicesMax(tablet) {
        //       font-size: 1.5vw;
        //     }

        //     @include devicesMax(mobile) {
        //       font-size: 3.25vw;
        //     }
        //   }

        //   .link {
        //     display: inline-block;
        //     text-decoration: underline;
        //   }
        // }
      }

      .sendBtn {
        @include dims(14vw, 3.75vw);
        border: 1px solid #282828;
        border-radius: 32px;
        color: #282828;
        display: block;
        font-family: Moderat;
        font-size: 1.5vw;
        font-weight: 400;
        line-height: 100%;
        margin-top: 4vw;
        opacity: 0.3;
        pointer-events: none;
        transition: 0.4s all ease;
        transform-origin: center;
        @extend .cursorPointer;

        &:hover {
          background: #fef8a6;
          transform: rotate(-9deg);
        }

        &.enabled {
          opacity: 1;
          pointer-events: auto;
        }

        @include devicesMax(tablet) {
          @include dims(20vw, 5vw);
          font-size: 2vw;
          margin-top: 5vw;
        }

        @include devicesMax(mobile) {
          @include dims(55vw, 14vw);
          font-size: 6vw;
          margin: 10vw auto 0 auto;
        }
      }
    }

    .stickers {
      margin-bottom: auto;
      margin-top: 3vw;
      transform: translateX(2vw);

      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: flex-end;

      @include devicesMax(tablet) {
        margin-top: 25vw;
        transform: translateX(0);
      }

      @include devicesMax(mobile) {
        margin: 0 0 20vw 0;
      }

      .sticker {
        border-radius: 44px;
        display: inline-block;
        white-space: nowrap;

        display: flex;
        align-items: center;

        @include devicesMax(mobile) {
          @include position(absolute, auto, auto, auto, 50%);
          transform: translate(-50%, -50%);
        }

        &.stickerAsk {
          background: #efefff;
          color: #282828;
          font-family: Moderat;
          font-weight: 400;
          font-size: 6.5vw;
          line-height: 100%;
          padding: 2rem 10rem 2rem 5rem;
          transform: rotate(10deg);

          @include devicesMax(mobile) {
            font-size: 22vw;
            padding: 2rem;
            transform: translate(-50%, -90%) rotate(10deg);
          }
        }

        &.stickerContact {
          background: #effffd;
          color: #282828;
          font-family: Moderat;
          font-weight: 400;
          font-size: 5vw;
          line-height: 100%;
          padding: 1.5rem 10rem 1.5rem 5rem;
          transform: rotate(-7deg);

          @include devicesMax(mobile) {
            font-size: 24vw;
            padding: 1.75rem 2rem;
            transform: translate(-54%, 0%) rotate(-7deg);
          }
        }

        .text {
          transform: translateY(-5%);
        }

        .imgW {
          @include dims(6vw);
          display: inline-block;
          margin-left: 3vw;
          transform: rotate(-7deg);

          @include devicesMax(mobile) {
            @include dims(14vw);
          }
        }
      }
    }
  }
}
