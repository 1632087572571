@import "assets/scss/utils.scss";
@import "assets/scss/variables.scss";

.brandDetail {
  $contentWidth: 75%;
  $contentWidthTablet: 80%;
  $contentWidthMobile: 85%;
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background: #fff;

  &::after {
    content: "";
    @include position(absolute, auto, 0, 0, 50%);
    @include dims($contentWidth, 1px);
    background: #adadad;
    transform: translateX(-50%);

    @include devicesMax(tablet) {
      @include dims($contentWidthTablet, 1px);
    }

    @include devicesMax(mobile) {
      @include dims($contentWidthMobile, 1px);
    }
  }

  .iconTag {
    @include position(absolute, 0, auto, auto, 0);
    @include dims(15vw);
    transform: translateX(-50%);

    @include devicesMax(tablet) {
      @include dims(25vw);
    }

    @include devicesMax(mobile) {
      display: none;
    }
  }

  .logoC {
    @include dims(20vw, 15vw);
    margin: 2.5vw 0;

    @include devicesMax(tablet) {
      @include dims(30vw, 20vw);
      margin: 5vw 0;
    }

    @include devicesMax(mobile) {
      @include dims(60vw, 40vw);
      margin: 15vw 0 10vw 0;
      z-index: 5;
    }
  }

  .brandInfo {
    @include dims($contentWidth, 6vw);
    border-bottom: 1px solid #adadad;
    border-top: 1px solid #adadad;
    margin-bottom: 4vw;
    display: grid;
    align-items: center;
    grid-template-columns: 1fr 2fr;

    @include devicesMax(tablet) {
      @include dims($contentWidthTablet, 8vw);
    }

    @include devicesMax(mobile) {
      @include dims($contentWidthMobile, auto);
      padding: 5vw 0;
    }

    @include devicesMax(mobile) {
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
    }

    .info {
      color: #282828;
      font-family: Moderat;
      font-size: 1.25vw;
      line-height: 100%;

      @include devicesMax(tablet) {
        font-size: 1.75vw;
      }

      @include devicesMax(mobile) {
        font-size: 4.5vw;
        margin-bottom: 4vw;

        &:last-child {
          margin-bottom: 0;
        }
      }

      .title {
        font-weight: 700;
      }

      .text {
        font-weight: 400;
        margin-left: 0.5rem;
      }
    }

    .right {
      display: flex;
      align-items: center;

      @include devicesMax(mobile) {
        flex-direction: column;
      }
    }

    .infoBtnW {
      margin-left: auto;

      @include devicesMax(mobile) {
        margin-left: 0;
        margin-top: 5vw;
      }

      .seeMenuW {
        @include dims(12vw, 3vw);
        display: block;

        @include devicesMax(tablet) {
          @include dims(15vw, 4vw);
        }

        @include devicesMax(mobile) {
          @include dims(40vw, 10vw);
        }
      }

      .reservationW {
        @include dims(12vw, 3vw);
        display: block;

        @include devicesMax(tablet) {
          @include dims(18vw, 4vw);
        }

        @include devicesMax(mobile) {
          @include dims(50vw, 12vw);
        }
      }
    }
  }

  .detailedInfoW {
    @include dims($contentWidth, auto);
    display: flex;
    flex-direction: column;

    @include devicesMax(tablet) {
      @include dims($contentWidthTablet, auto);
    }

    @include devicesMax(mobile) {
      @include dims($contentWidthMobile, auto);
    }

    .infoW {
      margin-bottom: 5vw;
      display: grid;
      grid-template-columns: 1fr 2fr;

      @include devicesMax(mobile) {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 10vw;
      }

      .detailTitle {
        color: #282828;
        font-family: Moderat;
        font-weight: 500;
        font-size: 1vw;
        line-height: 180%;
        margin-bottom: 4vw;
        text-align: left;
        text-decoration: underline;

        @include devicesMax(tablet) {
          font-size: 1.5vw;
        }

        @include devicesMax(mobile) {
          font-size: 4.5vw;
        }

        .doodle {
          @include dims(10vw);
          transform: rotate(7deg);

          @include devicesMax(tablet) {
            @include dims(15vw);
          }

          @include devicesMax(mobile) {
            display: none;
          }
        }
      }

      .detailInfo {
        color: #282828;
        font-family: Moderat;
        font-weight: 400;
        font-size: 1.15vw;
        line-height: 180%;
        text-align: left;

        @include devicesMax(tablet) {
          font-size: 1.75vw;
        }

        @include devicesMax(mobile) {
          font-size: 4.25vw;
          line-height: 200%;
          text-align: center;
        }

        .par {
          margin-bottom: 2vw;
          display: flex;
          align-items: center;

          .bullet {
            @include dims(6px);
            background: #000;
            border-radius: 50%;
            display: block;
            margin-right: 1rem;
          }
        }
      }
    }
  }

  .sliderW {
    @include dims(100%, auto);
    overflow: hidden;
    padding: 0 5vw;

    @include devicesMax(mobile) {
      padding: 10vw 0;
    }
  }

  .singleImgW {
    @include dims(80%, auto);
  }

  .btnW {
    @include dims(16vw, 4vw);
    margin: 5vw 0;

    @include devicesMax(tablet) {
      @include dims(20vw, 5vw);
      margin: 10vw 0 5vw 0;
    }

    @include devicesMax(mobile) {
      @include dims(55vw, 12vw);
      margin: 10vw 0;
    }
  }
}
