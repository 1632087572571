@import "assets/scss/utils.scss";

.aboutUs {
  position: relative;
  @extend .horizontalBorders;

  background: url("../img/bg-strip-home.svg");
  background-position: 100% 0;
  background-repeat: no-repeat;

  @include devicesMax(mobile) {
    background: none;
  }

  .titleW {
    @include dims(68vw, auto);
    margin: 10vw auto 5vw auto;

    display: flex;
    flex-direction: column;

    @include devicesMax(mobile) {
      margin: 20vw auto 0 auto;
    }

    .title {
      color: #282828;
      display: inline-block;
      line-height: 100%;

      &:nth-child(1) {
        font-size: 4.5vw;
        font-style: italic;
        font-family: Moderat;
        font-weight: 300;

        @include devicesMax(mobile) {
          font-size: 12vw;
          margin-bottom: 2vw;
          text-align: center;
        }
      }

      &:nth-child(2) {
        font-size: 8.5vw;
        font-weight: 500;
        font-family: ModeratExtended;

        @include devicesMax(mobile) {
          font-size: 17vw;
          margin-bottom: 10vw;
          text-align: center;
        }
      }
    }
  }

  .details {
    @include dims(67vw, auto);
    margin: 5vw auto 10vw auto;
    position: relative;

    @include devicesMax(mobile) {
      @include dims(100vw, auto);
      margin: 0 auto 10vw auto;
    }

    .textW {
      margin-bottom: 3vw;
      display: flex;

      @include devicesMax(mobile) {
        width: 100%;
      }

      &:last-child {
        margin-bottom: 0;
      }

      &:nth-child(1) {
        margin-bottom: 5vw;
        justify-content: flex-start;

        .parW {
          width: 56%;

          @include devicesMax(mobile) {
            margin: 0 auto;
            width: 90%;
          }
        }
      }

      &:nth-child(2) {
        align-items: flex-start;
        justify-content: flex-end;

        @include devicesMax(mobile) {
          flex-direction: column;
          align-items: center;
          justify-content: center;
        }

        .parW {
          margin-left: 3vw;
          width: 56%;

          @include devicesMax(mobile) {
            margin: 0 auto;
            width: 90%;
          }
        }
      }

      .imgW {
        @include dims(23vw);
        flex-shrink: 0;

        @include devicesMax(mobile) {
          @include dims(85vw);
          font-size: 4.5vw;
          margin-bottom: 10vw;
          text-align: center;
        }
      }

      .par {
        color: #282828;
        font-family: Moderat;
        font-weight: 400;
        font-size: 1.25vw;
        line-height: 180%;
        margin-bottom: 2vw;

        &:last-child {
          margin-bottom: 0;
        }

        @include devicesMax(mobile) {
          font-size: 4.5vw;
          margin-bottom: 10vw;
          text-align: center;
        }
      }
    }
  }

  // .midImg {
  //   padding: 0 15vw;

  //   .imgW {
  //     @include dims(auto, 40vw);
  //     margin: 3vw auto;
  //   }
  // }

  .slider {
    @include dims(70vw, 47vw);
    margin: 3vw auto;
    padding: 8px;
    position: relative;

    @include devicesMax(mobile) {
      @include dims(100vw, auto);
      margin: 5vw 0;
    }

    .img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .btn {
      @include position(absolute, 50%, auto, auto, auto);
      @include dims(10vw);
      transform: translateY(-50%);
      z-index: 10;
      @extend .cursorPointer;

      display: flex;
      align-items: center;
      justify-content: center;

      @include devicesMax(mobile) {
        @include dims(20vw);
      }

      &::after {
        content: "";
        @include position(absolute, 0, 0, 0, 0);
        @include dims(100%);
        background: #fff;
        border-radius: 50%;
      }

      .iconW {
        @include dims(25%, auto);

        .t {
          position: relative;
          transform: rotateY(180deg);
          z-index: 10;
        }
      }

      &.prev {
        left: 0;
        transform: translate(-25%, -50%);

        &::after {
          transform: translateX(-20%);
        }

        .iconW {
          display: block;
          transform-origin: center;
        }
      }

      &.next {
        right: 0;
        transform: translate(25%, -50%);

        &::after {
          transform: translateX(20%);
        }
      }
    }
  }

  .visMisW {
    border-top: 1px solid #adadad;
    border-bottom: 1px solid #adadad;
    margin: 0 calc($frameWidth + 1vw);
    position: relative;

    display: grid;
    grid-template-columns: repeat(2, 1fr);

    @include devicesMax(mobile) {
      border-top: none;
      grid-template-columns: repeat(1, auto);
      margin: 0 4vw;
      padding-bottom: 10vw;
    }

    // seperator
    &::after {
      content: "";
      @include dims(1px, 35%);
      @include position(absolute, 50%, auto, auto, 50%);
      background: #adadad;
      transform: translate(-50%, -50%);

      @include devicesMax(mobile) {
        display: none;
      }
    }

    .visMis {
      margin: 4vw 7vw 3vw 7vw;
      text-align: center;

      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;

      @include devicesMax(mobile) {
        margin: 10vw 0;
      }

      .imgW {
        @include dims(12.5vw);
        margin-bottom: 1.5vw;

        @include devicesMax(mobile) {
          @include dims(70vw);
          margin-bottom: 10vw;
        }
      }

      .title {
        color: #282828;
        font-family: Moderat;
        font-weight: 500;
        font-size: 2.25vw;
        line-height: 100%;
        margin-bottom: 1.5vw;

        @include devicesMax(mobile) {
          font-size: 12vw;
          margin-bottom: 10vw;
          text-align: center;
        }
      }

      .par {
        color: #282828;
        font-family: Moderat;
        font-weight: 400;
        font-size: 1.15vw;
        line-height: 180%;
        margin-bottom: 1.5vw;

        &:last-child {
          margin-bottom: 1.5vw;
        }

        @include devicesMax(mobile) {
          font-size: 4.5vw;
          margin-bottom: 2vw;
          text-align: center;
        }
      }
    }
  }
}
