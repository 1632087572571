@import "assets/scss/utils.scss";
@import "assets/scss/variables.scss";

.selectWrapper {
  @include dims(100%);
  @extend .cursorPointer;
  border: 1px solid #3b3b3b;
  border-radius: 32px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;

  .lngText {
    @include position(absolute, 50%, auto, auto, 50%);
    transform: translate(-50%, -50%);
    color: #3b3b3b;
    display: block;
    font-family: Moderat;
    font-size: 14px;
    font-weight: 500;
    line-height: 100%;
    @extend .hide;

    @include devicesMax(mobile) {
      font-size: 4.5vw;
    }

    &.enabled {
      @extend .show;
    }
  }
}
