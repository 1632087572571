@import "./utils.scss";

.ourBrands {
  position: relative;
  @extend .horizontalBorders;

  .intro {
    @extend .verticalBorderBottom;
    padding: 6vw 0;

    @include devicesMax(mobile) {
      padding: 20vw 0 5vw 0;

      &::after {
        display: none;
      }
    }

    .titleW {
      margin: 0 8vw 2vw 8vw;

      @include devicesMax(mobile) {
        margin: 0;
      }

      .title {
        color: #282828;
        line-height: 100%;

        &:nth-child(1) {
          font-family: Moderat;
          font-weight: 300;
          font-size: 2.5vw;
          font-style: italic;

          @include devicesMax(mobile) {
            font-size: 12vw;
            margin-bottom: 2vw;
            text-align: center;
          }
        }

        &:nth-child(2) {
          font-family: ModeratExtended;
          font-weight: 500;
          font-size: 4vw;

          @include devicesMax(mobile) {
            font-size: 15vw;
            margin-bottom: 10vw;
            text-align: center;
          }
        }
      }
    }

    .desc {
      color: #282828;
      font-family: Moderat;
      font-weight: 400;
      font-size: 1.25vw;
      line-height: 180%;
      margin: 0 8vw;
      width: 33%;

      @include devicesMax(mobile) {
        font-size: 4.5vw;
        margin: 0 auto 10vw auto;
        text-align: center;
        width: 90%;
      }
    }

    .imgW {
      @include position(absolute, 10vw, 10vw, auto, auto);
      @include dims(25vw);

      @include devicesMax(mobile) {
        @include position(relative, auto, auto, auto, auto);
        @include dims(80vw);
        margin: 0 auto;
      }

      &::after {
        content: "";
        @include position(absolute, 50%, auto, auto, 50%);
        @include dims(100%);
        transform: translate(-50%, -50%);
        background: #fff;
        z-index: z-index("bg");

        @include devicesMax(mobile) {
          display: none;
        }
      }
    }
  }

  .brandsFilter {
    padding: 8vw 8vw 0 8vw;

    @include devicesMax(mobile) {
      padding: 8vw 4vw 0 4vw;
    }

    .oWrapper {
      overflow: hidden;
    }

    .clearFilters {
      margin-left: 7vw;
      pointer-events: none;
      transition: 0.4s all ease;
      @extend .cursorPointer;
      @extend .hide;

      display: flex;
      align-items: center;

      &.active {
        @extend .show;
        pointer-events: auto;
      }

      @include devicesMax(mobile) {
        margin-left: 0;
        margin-top: 4vw;
      }

      .text {
        color: #929292;
        font-family: ModeratExtended;
        font-weight: 500;
        font-size: 1.25vw;
        line-height: 100%;

        @include devicesMax(tablet) {
          font-size: 2vw;
        }

        @include devicesMax(mobile) {
          font-size: 4.5vw;
        }
      }
    }

    .filtersW {
      position: relative;
      transform: translateY(30%);
      transition: 0.4s all ease;
      transform-origin: center;

      &.open {
        transform: translateY(0%);
      }

      @include devicesMax(mobile) {
        transform: translateY(0%);
      }

      .filterTitle {
        color: #282828;
        display: inline-block;
        font-family: Moderat;
        font-weight: 500;
        font-size: 3.5vw;
        line-height: 100%;
        @extend .cursorPointer;

        @include devicesMax(tablet) {
          font-size: 5vw;
        }

        @include devicesMax(mobile) {
          font-size: 12vw;
        }

        .iconW {
          display: inline-flex;
          flex-shrink: 0;
          margin-left: 1rem;
          position: relative;

          .lottie {
            @include position(absolute, 50%, auto, auto, 50%);
            transform: translate(-50%, -45%);
          }
        }
      }

      .filters {
        margin-top: 1.5vw;
        position: relative;
        display: flex;

        @include devicesMax(mobile) {
          flex-direction: column;
          margin-top: 4vw;
        }

        .filter {
          color: #282828;
          flex-shrink: 0;
          font-family: ModeratExtended;
          font-weight: 500;
          font-size: 1.5vw;
          line-height: 140%;
          margin-right: 0.75rem;
          transition: 0.4s all ease;
          @extend .cursorPointer;

          &:hover,
          &.active {
            color: #a4f3e4;
          }

          &:last-child {
            margin-right: 0;
          }

          @include devicesMax(tablet) {
            font-size: 2vw;
          }

          @include devicesMax(mobile) {
            font-size: 4.5vw;
            margin-bottom: 2vw;
          }
        }
      }
    }
  }

  .brandsW {
    margin: 7vw;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 7vw 3vw;

    @include devicesMax(tablet) {
      margin: 7vw 7vw 10vw 7vw;
      grid-template-columns: repeat(2, 1fr);
      gap: 7vw 5vw;
    }

    @include devicesMax(mobile) {
      margin: 6vw 4vw 10vw 4vw;
      grid-template-columns: repeat(1, auto);
      gap: 10vw 0;
    }
  }
}
