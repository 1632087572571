.SimplePlane {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 45vw;
  height: 30vw;
}

.SimplePlane img {
  display: none;
}

.curtains-canvas {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  /* transform: rotate(-5deg); */
}
