@import "./utils.scss";

.fieldsOfActivity {
  position: relative;
  @extend .horizontalBorders;

  background: url("../img/bg-strip-fields.svg");
  background-position: 100% 35%;
  background-repeat: no-repeat;

  @include devicesMax(mobile) {
    background: none;
  }

  .intro {
    padding: 12.5vw 0 5vw 0;
    display: flex;

    @include devicesMax(mobile) {
      flex-direction: column;
      padding: 15vw 0 5vw 0;
    }

    .titleW {
      margin: 0 5vw 2vw 15vw;

      @include devicesMax(tablet) {
        margin: 0 5vw 2vw 10vw;
      }

      @include devicesMax(mobile) {
        margin: 0 0 5vw 0;
      }

      .title {
        color: #282828;
        line-height: 100%;

        &:nth-child(1) {
          font-family: Moderat;
          font-weight: 300;
          font-size: 4.75vw;
          font-style: italic;

          @include devicesMax(mobile) {
            font-size: 12vw;
            margin-bottom: 2vw;
            text-align: center;
          }
        }

        &:nth-child(2) {
          font-family: ModeratExtended;
          font-weight: 500;
          font-size: 8.5vw;

          @include devicesMax(mobile) {
            font-size: 17vw;
            margin-bottom: 10vw;
            text-align: center;
          }
        }
      }
    }

    .desc {
      color: #282828;
      font-family: Moderat;
      font-weight: 400;
      font-size: 1.25vw;
      line-height: 180%;
      margin-top: 5vw;
      width: 90%;

      @include devicesMax(tablet) {
        font-size: 2vw;
        width: 100%;
      }

      @include devicesMax(mobile) {
        font-size: 5.25vw;
        margin: 0 auto;
        text-align: center;
        width: 87%;
      }
    }

    .imgsW {
      display: flex;
      transform: translate(-2.5vw, -7.5vw);

      @include devicesMax(mobile) {
        margin-top: 5vw;
        transform: translate(0, 0);
      }

      .imgW {
        @include dims(18vw);

        @include devicesMax(tablet) {
          @include dims(20vw);
        }

        @include devicesMax(mobile) {
          @include dims(60vw);
        }

        &:nth-child(1) {
          @include devicesMax(mobile) {
            transform: translate(4vw, 0);
          }
        }

        &:nth-child(2) {
          transform: translate(-5vw, 10vw);

          @include devicesMax(mobile) {
            margin-top: 30vw;
            transform: translate(-4vw, 0);
          }
        }
      }
    }
  }

  .fields {
    border-bottom: 1px solid #adadad;
    margin: 0 calc($frameWidth + 1vw);
    padding: 3vw 0 0 0;
    position: relative;

    @include devicesMax(tablet) {
      padding: 5vw 0 0 0;
    }

    @include devicesMax(mobile) {
      margin: 0 0 25vw 0;
      padding: 5vw 0 10vw 0;
    }

    .stickers {
      @include position(absolute, auto, 2vw, -5vw, auto);
      display: flex;

      @include devicesMax(mobile) {
        @include position(absolute, auto, 2vw, -20vw, auto);
      }

      &::after {
        content: "";
        @include position(absolute, 0, 0, 0, 0);
        @include dims(90%, 100%);
        background: #fff;
      }

      .stickerW {
        @include dims(20vw);

        @include devicesMax(mobile) {
          @include dims(45vw);
        }

        &:nth-child(1) {
          z-index: 50;
        }

        &:nth-child(2) {
          // transform: translate(-5vw, 5vw);
          margin-right: 5vw;
          margin-left: -4vw;
          margin-top: 5vw;
          margin-bottom: -5vw;
          z-index: 100;
        }
      }
    }

    .row {
      margin-bottom: 5vw;
      display: flex;
      align-items: center;

      @include devicesMax(mobile) {
        flex-direction: column-reverse;
      }

      &.left {
        justify-content: flex-start;

        @include devicesMax(mobile) {
          justify-content: center;
        }
      }

      &.right {
        justify-content: center;
      }

      .imgW {
        @include dims(32vw, 22vw);
        border-radius: 8px;
        flex-shrink: 0;
        margin-top: 5vw;
        overflow: hidden;
        z-index: 0;

        @extend .flexcenter;

        @include devicesMax(tablet) {
          @include dims(40vw, 27vw);
          margin-left: -5vw;
          margin-right: 2.5vw;
        }

        @include devicesMax(mobile) {
          @include dims(140%, 386px);
          border-radius: none;
          margin: 0 0 10vw 0;
        }
      }

      .fieldCard {
        @include dims(37vw, auto);
        margin-left: 10vw;
        display: flex;
        align-items: flex-start;

        @include devicesMax(tablet) {
          @include dims(auto, auto);
          margin-left: 2vw;
        }

        @include devicesMax(mobile) {
          align-items: center;
          flex-direction: column;
          justify-content: center;
          margin: 0;
        }

        .index {
          $indexDims: 5vw;
          $indexDimsMobile: 20vw;

          @include dims($indexDims);
          color: #282828;
          flex-shrink: 0;
          font-family: Moderat;
          font-weight: 400;
          font-size: calc($indexDims - 1vw);
          line-height: 100%;
          margin-top: -1vw;
          text-align: center;
          padding-top: 0.25rem;

          background: url("../icon/bg-circle.svg");
          background-size: 95%;
          background-position: center;
          background-repeat: no-repeat;

          @include devicesMax(mobile) {
            @include dims($indexDimsMobile);
            font-size: calc($indexDimsMobile - 1rem);
            margin: 0 0 5vw 0;
          }
        }

        .cardContent {
          margin-left: 1vw;

          @include devicesMax(mobile) {
            margin: 0 0 20vw 0;
          }

          .cardTitle {
            color: #282828;
            font-family: Moderat;
            font-weight: 500;
            font-size: 3.25vw;
            line-height: 120%;
            margin-bottom: 1.5vw;

            @include devicesMax(tablet) {
              font-size: 4.5vw;
            }

            @include devicesMax(mobile) {
              font-size: 11vw;
              margin-bottom: 10vw;
              text-align: center;
            }
          }

          .par {
            @include dims(29vw, auto);
            color: #282828;
            font-family: Moderat;
            font-weight: 400;
            font-size: 1.25vw;
            line-height: 180%;
            margin-bottom: 2.5vw;

            &:last-child {
              margin-bottom: 0;
            }

            @include devicesMax(tablet) {
              @include dims(35vw, auto);
              font-size: 1.75vw;
            }

            @include devicesMax(mobile) {
              @include dims(85vw, auto);
              font-size: 5vw;
              margin-bottom: 10vw;
              text-align: center;
            }
          }
        }
      }
    }
  }
}
