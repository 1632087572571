@import "../scss/utils.scss";
@import "../scss/mixinContiniousRotate.scss";

.buttonCircle {
  @include dims(100%);
  display: block;
  position: relative;
  @extend .cursorPointer;

  @media only screen and (min-width: 1025px) {
    &:hover {
      .btnBg {
        .bgW {
          .rotationW {
            .oHidden {
              transform: scale(1);
            }
          }
        }
      }
    }
  }

  .btnBg {
    @include position(absolute, 0, 0, 0, 0);
    @include dims(100%);

    .bgW {
      @include position(absolute, 0, 0, 0, 0);
      @include dims(100%);

      &:nth-child(1) {
        z-index: 100;
      }

      &:nth-child(2) {
        z-index: 50;
      }

      .rotationW {
        @include position(absolute, 0, 0, 0, 0);
        @include dims(100%);
        @include rotating;
        will-change: transform;

        .oHidden {
          transform: scale(0);
          transition: 0.4s all ease-in-out;
          overflow: hidden;
        }
      }
    }
  }

  .btnInner {
    @extend .abscenter;
    z-index: 10000;

    .line {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .text {
        color: #282828;
        font-family: Moderat;
        font-size: 1.25rem;
        font-weight: 400;
        line-height: 100%;
        white-space: nowrap;

        @include devicesMax(tablet) {
          font-size: 1.15rem;
        }

        @include devicesMax(mobile) {
          font-size: 1.1rem;
          line-height: 120%;
        }
      }

      .iconW {
        @include dims(auto, 0.5rem);
        margin-left: 0.5rem;
        display: flex;
        align-items: center;

        @include devicesMax(tablet) {
          @include dims(auto, 0.5rem);
        }
      }
    }
  }
}
