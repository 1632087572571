@import "assets/scss/utils.scss";
@import "assets/scss/variables.scss";

.imageSlider {
  margin: 3vw auto;
  position: relative;

  @include devicesMax(mobile) {
    @include dims(100vw, auto);
    margin: 5vw 0;
  }

  .slideImgW {
    overflow: hidden;

    display: flex;
    align-items: center;
    justify-content: center;

    .img {
      @include dims(100%, auto);
      display: block;
      object-fit: cover;
    }
  }

  .btn {
    @include position(absolute, 50%, auto, auto, auto);
    @include dims(10vw);
    opacity: 1;
    pointer-events: auto;
    transform: translateY(-50%);
    transition: 0.4s all ease;
    z-index: 10;
    @extend .cursorPointer;

    display: flex;
    align-items: center;
    justify-content: center;

    @include devicesMax(mobile) {
      @include dims(10vw);
      background: #fff;
      border-radius: 50%;
    }

    &.disabled {
      opacity: 0;
      pointer-events: none;
    }

    .iconW {
      @include dims(15%, auto);

      @include devicesMax(mobile) {
        @include dims(50%, auto);
      }

      .t {
        position: relative;
        transform: rotateY(180deg);
        z-index: 10;
      }
    }

    &.prev {
      left: 0;
      transform: translate(-65%, -50%);

      @include devicesMax(mobile) {
        transform: translate(20%, -50%);
      }

      .iconW {
        display: block;
        transform-origin: center;
      }
    }

    &.next {
      right: 0;
      transform: translate(65%, -50%);

      @include devicesMax(mobile) {
        transform: translate(-20%, -50%);
      }
    }
  }
}
