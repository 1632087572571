@import "assets/scss/utils.scss";
@import "assets/scss/variables.scss";

.home {
  background: url("../img/bg-strip-home.svg");
  background-position: 100% 0;
  background-repeat: no-repeat;

  .hero {
    @include dims(auto, calc(100vh - $headerHeight));
    @extend .horizontalBorders;

    display: flex;
    align-items: center;
    justify-content: center;

    .textW {
      margin: 0 auto;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      .line {
        @include dims(100%, auto);
        display: flex;
        align-items: center;
        justify-content: space-between;

        &.preFlight {
          margin-bottom: 1.5vw;

          @include devicesMax(tablet) {
            flex-direction: column;
            margin-bottom: 0;

            .title {
              font-size: 15vw;
              margin-bottom: 8vw;
            }

            .imgW {
              margin-bottom: 8vw;

              &.luggages {
                @include dims(70vw, auto);
                margin-right: 2vw;
              }
            }
          }

          @include devicesMax(mobile) {
            .title {
              font-size: 18.5vw;
            }

            .imgW {
              &.luggages {
                @include dims(87.5vw, auto);
              }
            }
          }
        }

        &.management {
          &.desktop {
            @include devicesMax(tablet) {
              display: none;
            }
          }

          &.touch {
            display: none;

            @include devicesMax(tablet) {
              display: flex;
              flex-wrap: wrap;
              width: 70vw;

              .title {
                font-size: 14.5vw;
                margin-bottom: 5vw;
              }

              .imgW {
                &.pattern {
                  @include dims(25vw, auto);
                  margin-right: 2vw;
                }
              }
            }

            @include devicesMax(mobile) {
              width: 90vw;

              .title {
                font-size: 18.5vw;
              }

              .imgW {
                &.pattern {
                  @include dims(32vw, auto);
                }
              }
            }
          }
        }

        .title {
          color: #282828;
          display: inline-block;
          font-family: ModeratExtended;
          font-weight: 500;
          font-size: 9.5vw;
          line-height: 100%;
          white-space: nowrap;
        }

        .imgW {
          flex-shrink: 0;
          display: inline-block;

          &.luggages {
            @include dims(35vw, 11vw);
            margin-left: 1vw;
          }

          &.pattern {
            @include dims(15vw, 12vw);
            margin-right: 2vw;
          }
        }
      }
    }
  }

  .info {
    padding-bottom: 5vw;
    @extend .horizontalBorders;

    .charW {
      display: flex;
      align-items: center;
      justify-content: center;

      &::after,
      &::before {
        content: "";
        @include dims(100%, 1px);
        background: #adadad;
        margin: 0 2.5vw;

        @include devicesMax(mobile) {
          display: none;
        }
      }

      .imgW {
        @include dims(20vw);
        flex-shrink: 0;

        @include devicesMax(tablet) {
          @include dims(30vw);
        }

        @include devicesMax(mobile) {
          @include dims(60vw);
        }
      }
    }

    .textW {
      @include dims(40vw, auto);
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      margin: 0 auto;

      @include devicesMax(tablet) {
        @include dims(60vw, auto);
      }

      @include devicesMax(mobile) {
        @include dims(100%, auto);
      }

      .title {
        color: #282828;
        font-family: Moderat;
        font-weight: 500;
        font-size: 2.5vw;
        line-height: 100%;
        margin-bottom: 2vw;

        @include devicesMax(tablet) {
          font-size: 3.5vw;
        }

        @include devicesMax(mobile) {
          font-size: 12vw;
          line-height: 120%;
          margin-bottom: 10vw;
          text-align: center;
          width: 80%;
        }
      }

      .par {
        color: #282828;
        font-family: Moderat;
        font-weight: 400;
        font-size: 1.25vw;
        line-height: 180%;
        margin-bottom: 3vw;
        text-align: center;

        @include devicesMax(tablet) {
          font-size: 2vw;
        }

        @include devicesMax(mobile) {
          font-size: 5.5vw;
          margin-bottom: 15vw;
          width: 85%;
        }
      }

      .btnW {
        @include dims(16vw, 4vw);

        @include devicesMax(tablet) {
          @include dims(24vw, 6vw);
        }

        @include devicesMax(mobile) {
          @include dims(65vw, 14vw);
        }
      }
    }
  }

  .marqueesW {
    padding: 10vw 0 20vw 0;
    position: relative;
    @extend .horizontalBorders;

    @include devicesMax(mobile) {
      @include dims(100%, 100vw);
      margin: 20vw 0 5vw 0;
      padding: 0;
    }

    .borderBottom {
      @include position(absolute, auto, 0, 0, 50%);
      @include dims(calc(100vw - ($frameWidth * 4)), 1px);
      background: #adadad;
      transform: translateX(-50%);
      z-index: -1;

      @include devicesMax(tablet) {
        display: none;
      }
    }

    // .marqueeItem {
    //   display: inline;

    //   .text {
    //     font-family: Moderat;
    //     font-size: 8vw;
    //     line-height: 100%;
    //     transform: translateY(-5%);

    //     &.hollow {
    //       -webkit-text-stroke: 1px rgba(0, 0, 0, 0.461);
    //       -webkit-text-fill-color: transparent;
    //     }
    //   }

    //   .imgW {
    //     display: inline-block;
    //     @include dims(8vw);
    //   }
    // }

    .mWrapper {
      width: 150vw;

      &:nth-child(1) {
        background: #effffd;
        transform: translateX(-25%);
      }

      &:nth-child(2) {
        background: #efefff;
        transform: rotate(-13deg) translateY(-12vw) translateX(-25%);

        @include devicesMax(mobile) {
          transform: rotate(-13deg) translateY(-17vw) translateX(-25%);
        }
      }

      .marqueeItem {
        display: flex;
        flex-shrink: 0;
        align-items: center;
        padding: 0 4px;

        .text {
          font-family: Moderat;
          font-size: 9vw;
          line-height: 100%;
          margin: 2vw 0;
          transform: translateY(-5%);

          @include devicesMax(mobile) {
            font-size: 15vw;
            margin: 4vw 0;
          }

          &.hollow {
            -webkit-text-stroke: 1px rgba(0, 0, 0, 0.461);
            -webkit-text-fill-color: transparent;
          }
        }

        .imgW {
          @include dims(8vw);
          display: inline-block;

          @include devicesMax(mobile) {
            @include dims(14vw);
          }
        }
      }
    }

    .floatingImgsW {
      @include position(absolute, 50%, auto, auto, 50%);
      transform: translate(-50%, -70%);

      display: flex;
      align-items: center;
      justify-content: center;

      .imgW {
        @include dims(25vw, 17.5vw);
        border-radius: 32px;
        margin: 0 1rem;
        overflow: hidden;

        &:nth-child(1) {
          transform: rotate(-5deg);

          @include devicesMax(mobile) {
            display: none;
          }
        }

        &:nth-child(2) {
          transform: rotate(10deg);
          margin-bottom: 15vw;

          @include devicesMax(mobile) {
            @include dims(70vw, 45vw);
            border-radius: 8px;
            margin-bottom: -45vw;
          }
        }
      }
    }
  }

  .fields {
    padding-bottom: 5vw;
    position: relative;
    @extend .horizontalBorders;

    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat((2, 1fr));

    @include devicesMax(mobile) {
      display: flex;
      flex-direction: column;
    }

    .fieldW {
      display: flex;
      align-items: center;
      justify-content: center;

      &.management {
        margin-right: -5vw;
        padding: 0;

        @include devicesMax(mobile) {
          display: none;
        }

        .fieldCard {
          margin-left: auto;

          .imgW {
            @include dims(30vw, 20vw);
          }
        }
      }

      &.catering {
        padding: 0 7vw;

        @include devicesMax(mobile) {
          display: none;
        }

        .fieldCard {
          margin-right: auto;

          .imgW {
            @include dims(30vw, 20vw);
          }
        }
      }

      &.cip {
        padding: 0 7vw;
        transform: translateY(-13vw);

        @include devicesMax(mobile) {
          display: none;
        }

        .fieldCard {
          margin-left: auto;

          .imgW {
            @include dims(22vw, 34vw);
          }
        }
      }

      &.text {
        padding: 0 5vw;

        @include devicesMax(mobile) {
          padding: 0;
        }

        .textW {
          background: #fff;
          margin-left: auto;
          padding-left: 4vw;
          transform: translateY(-15vw);

          display: flex;
          flex-direction: column;

          @include devicesMax(mobile) {
            padding: 0;
            transform: translateY(0);
          }

          .title {
            color: #282828;
            font-family: Moderat;
            font-weight: 500;
            font-size: 3vw;
            line-height: 120%;
            margin-bottom: 2vw;
            width: 75%;

            @include devicesMax(tablet) {
              font-size: 3.25vw;
              width: 80%;
            }

            @include devicesMax(mobile) {
              font-size: 11vw;
              line-height: 120%;
              margin: 10vw auto;
              text-align: center;
              width: 85%;
            }
          }

          .par {
            color: #282828;
            font-family: Moderat;
            font-weight: 400;
            font-size: 1.25vw;
            line-height: 140%;
            margin-bottom: 0.5rem;
            width: 85%;

            @include devicesMax(tablet) {
              font-size: 1.75vw;
            }

            @include devicesMax(mobile) {
              font-size: 5.5vw;
              margin: 0 auto 15vw auto;
              line-height: 180%;
              text-align: center;
              width: 80%;
            }
          }
        }
      }

      .fieldCard {
        .imgW {
          border-radius: 8px;
          margin-bottom: 0.75rem;
          overflow: hidden;
          position: relative;
          z-index: 0;
        }

        .fieldTitle {
          color: #282828;
          font-family: Moderat;
          font-weight: 500;
          font-size: 2vw;
          line-height: 100%;
          margin-bottom: 0.5rem;

          @include devicesMax(tablet) {
            font-size: 2.5vw;
          }
        }

        .fieldDetail {
          color: #282828;
          font-family: Moderat;
          font-weight: 400;
          font-size: 1vw;
          line-height: 100%;

          @include devicesMax(tablet) {
            font-size: 1.5vw;
          }
        }
      }
    }

    .btnCircleW {
      @include position(absolute, 50%, auto, auto, 50%);
      @include dims(12vw);
      transform: translate(-30%, -90%);

      @include devicesMax(tablet) {
        @include dims(17vw);
        transform: translate(-40%, -80%);
      }

      @include devicesMax(mobile) {
        display: none;
      }
    }

    .bgImgW {
      @include position(absolute, 37vw, auto, auto, -5vw);
      @include dims(24vw);
      z-index: -2;

      @include devicesMax(mobile) {
        display: none;
      }
    }

    .sliderMobile {
      position: relative;

      .sliderCard {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;

        padding: 5vw 0;

        .imgW {
          @include dims(100%, 65vw);
          margin-bottom: 5vw;
          padding: 0 4vw;

          .img {
            @include dims(100%);
            display: block;
            object-fit: cover;
          }
        }

        .title {
          color: #282828;
          font-family: Moderat;
          font-weight: 500;
          font-size: 5.5vw;
          line-height: 100%;
          margin-bottom: 3vw;
        }

        .desc {
          color: #282828;
          font-family: Moderat;
          font-weight: 300;
          font-size: 4vw;
          line-height: 100%;
        }
      }

      .btn {
        @include position(absolute, 90%, auto, auto, auto);
        @include dims(10vw);
        transition: 0.4s opacity ease;
        z-index: 10;
        @extend .cursorPointer;

        display: flex;
        align-items: center;
        justify-content: center;

        @include devicesMax(mobile) {
          @include dims(20vw);
        }

        &.disabled {
          opacity: 0.3;
          pointer-events: none;
        }

        .iconW {
          @include dims(25%, auto);

          .t {
            position: relative;
            transform: rotateY(180deg);
            z-index: 10;
          }
        }

        &.prev {
          left: 2vw;
          transform: translate(-25%, -50%);

          &::after {
            transform: translateX(-20%);
          }

          .iconW {
            display: block;
            transform-origin: center;
          }
        }

        &.next {
          right: 2vw;
          transform: translate(25%, -50%);

          &::after {
            transform: translateX(20%);
          }
        }
      }
    }
  }

  .animated {
    position: relative;

    &::after {
      content: "";
      @include position(absolute, 0, auto, auto, 0);
      @include dims(120%, 50%);
      background: #fff;
      transform: translate(0, 0) rotate(-6deg);
      z-index: -1;
    }

    .top {
      padding: 10vw 15vw;
      position: relative;
      @extend .horizontalBorders;

      @include devicesMax(tablet) {
        padding: 10vw 15vw 0 15vw;
      }

      @include devicesMax(mobile) {
        padding: 10vw 0 0 0;
      }

      .imgW {
        @include position(absolute, auto, auto, auto, auto);

        @include devicesMax(mobile) {
          display: none;
        }

        &.charDive {
          @include position(absolute, 15vw, 0, auto, auto);
          @include dims(17vw);
        }

        &.cloud {
          @include position(absolute, 15vw, auto, auto, -3vw);
          @include dims(12vw);
        }

        &.cloudBgBlue {
          @include position(absolute, 3vw, 20vw, auto, auto);
          @include dims(22vw);
        }
      }

      .text {
        .title {
          color: #282828;
          font-family: Moderat;
          font-weight: 500;
          font-size: 3vw;
          line-height: 120%;
          margin-bottom: 2vw;
          width: 45%;

          @include devicesMax(tablet) {
            font-size: 3.5vw;
            width: 50%;
          }

          @include devicesMax(mobile) {
            font-size: 11vw;
            line-height: 120%;
            margin: 10vw auto;
            text-align: center;
            width: 85%;
          }
        }

        .par {
          color: #282828;
          font-family: Moderat;
          font-weight: 400;
          font-size: 1.25vw;
          line-height: 140%;
          margin-bottom: 0.5rem;
          width: 45%;

          @include devicesMax(tablet) {
            font-size: 2.25vw;
            width: 45%;
          }

          @include devicesMax(mobile) {
            font-size: 5.5vw;
            margin: 0 auto 0 auto;
            line-height: 180%;
            text-align: center;
            width: 70%;
          }
        }
      }
    }

    .wooo {
      @include dims(100vw, 100vh);
      overflow: hidden;
      padding: 10vw 0;
      position: relative;

      @include devicesMax(tablet) {
        padding: 40vw 0;
      }

      // .mickeyW {
      //   @include position(absolute, -1vw, auto, auto, 44vw);
      //   @include dims(20vw);
      // }

      .woooStrip {
        display: flex;
        align-items: center;

        .woooItem {
          flex-shrink: 0;
          margin: 0 7.5vw;

          &.charWoo {
            @include dims(50vw, auto);
          }

          &.brandLogo {
            @include dims(20vw);
          }

          &.btnW {
            @include dims(15vw);
            display: block;
          }
        }
      }
    }
  }
}
