// Moderat
@font-face {
  font-family: "Moderat";
  src: url("./fontfiles/Moderat/Moderat-Black.woff2") format("woff2"),
    url("./fontfiles/Moderat/Moderat-Black.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Moderat";
  src: url("./fontfiles/Moderat/Moderat-Bold.woff2") format("woff2"),
    url("./fontfiles/Moderat/Moderat-Bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Moderat";
  src: url("./fontfiles/Moderat/Moderat-Medium.woff2") format("woff2"),
    url("./fontfiles/Moderat/Moderat-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Moderat";
  src: url("./fontfiles/Moderat/Moderat-Regular.woff2") format("woff2"),
    url("./fontfiles/Moderat/Moderat-Regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Moderat";
  src: url("./fontfiles/Moderat/Moderat-Light.woff2") format("woff2"),
    url("./fontfiles/Moderat/Moderat-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Moderat";
  src: url("./fontfiles/Moderat/Moderat-Thin.woff2") format("woff2"),
    url("./fontfiles/Moderat/Moderat-Thin.woff") format("woff");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

// Moderat Mono
// @font-face {
//   font-family: "ModeratMono";
//   src: url("./fontfiles/ModeratMono/Moderat-Mono-Black.woff2") format("woff2"),
//     url("./fontfiles/ModeratMono/Moderat-Mono-Black.woff") format("woff");
//   font-weight: 900;
//   font-style: normal;
//   font-display: swap;
// }

// @font-face {
//   font-family: "Moderat";
//   src: url("./fontfiles/ModeratMono/Moderat-Mono-Bold.woff2") format("woff2"),
//     url("./fontfiles/ModeratMono/Moderat-Mono-Bold.woff") format("woff");
//   font-weight: 700;
//   font-style: normal;
//   font-display: swap;
// }

// @font-face {
//   font-family: "ModeratMono";
//   src: url("./fontfiles/ModeratMono/Moderat-Mono-Medium.woff2") format("woff2"),
//     url("./fontfiles/ModeratMono/Moderat-Mono-Medium.woff") format("woff");
//   font-weight: 500;
//   font-style: normal;
//   font-display: swap;
// }

// @font-face {
//   font-family: "ModeratMono";
//   src: url("./fontfiles/ModeratMono/Moderat-Mono-Regular.woff2") format("woff2"),
//     url("./fontfiles/ModeratMono/Moderat-Mono-Regular.woff") format("woff");
//   font-weight: 400;
//   font-style: normal;
//   font-display: swap;
// }

// @font-face {
//   font-family: "ModeratMono";
//   src: url("./fontfiles/ModeratMono/Moderat-Mono-Light.woff2") format("woff2"),
//     url("./fontfiles/ModeratMono/Moderat-Mono-Light.woff") format("woff");
//   font-weight: 300;
//   font-style: normal;
//   font-display: swap;
// }

// @font-face {
//   font-family: "ModeratMono";
//   src: url("./fontfiles/ModeratMono/Moderat-Mono-Thin.woff2") format("woff2"),
//     url("./fontfiles/ModeratMono/Moderat-Mono-Thin.woff") format("woff");
//   font-weight: 100;
//   font-style: normal;
//   font-display: swap;
// }

// Moderat Condensed
@font-face {
  font-family: "ModeratCondensed";
  src: url("./fontfiles/ModeratCondensed/Moderat-Condensed-Black.woff2") format("woff2"),
    url("./fontfiles/ModeratCondensed/Moderat-Condensed-Black.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "ModeratCondensed";
  src: url("./fontfiles/ModeratCondensed/Moderat-Condensed-Bold.woff2") format("woff2"),
    url("./fontfiles/ModeratCondensed/Moderat-Condensed-Bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "ModeratCondensed";
  src: url("./fontfiles/ModeratCondensed/Moderat-Condensed-Medium.woff2") format("woff2"),
    url("./fontfiles/ModeratCondensed/Moderat-Condensed-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "ModeratCondensed";
  src: url("./fontfiles/ModeratCondensed/Moderat-Condensed-Regular.woff2") format("woff2"),
    url("./fontfiles/ModeratCondensed/Moderat-Condensed-Regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "ModeratCondensed";
  src: url("./fontfiles/ModeratCondensed/Moderat-Condensed-Light.woff2") format("woff2"),
    url("./fontfiles/ModeratCondensed/Moderat-Condensed-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "ModeratCondensed";
  src: url("./fontfiles/ModeratCondensed/Moderat-Condensed-Thin.woff2") format("woff2"),
    url("./fontfiles/ModeratCondensed/Moderat-Condensed-Thin.woff") format("woff");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

// Moderat Extendend
@font-face {
  font-family: "ModeratExtended";
  src: url("./fontfiles/ModeratExtended/Moderat-Extended-Black.woff2") format("woff2"),
    url("./fontfiles/ModeratExtended/Moderat-Extended-Black.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "ModeratExtended";
  src: url("./fontfiles/ModeratExtended/Moderat-Extended-Bold.woff2") format("woff2"),
    url("./fontfiles/ModeratExtended/Moderat-Extended-Bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "ModeratExtended";
  src: url("./fontfiles/ModeratExtended/Moderat-Extended-Medium.woff2") format("woff2"),
    url("./fontfiles/ModeratExtended/Moderat-Extended-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "ModeratExtended";
  src: url("./fontfiles/ModeratExtended/Moderat-Extended-Regular.woff2") format("woff2"),
    url("./fontfiles/ModeratExtended/Moderat-Extended-Regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "ModeratExtended";
  src: url("./fontfiles/ModeratExtended/Moderat-Extended-Light.woff2") format("woff2"),
    url("./fontfiles/ModeratExtended/Moderat-Extended-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "ModeratExtended";
  src: url("./fontfiles/ModeratExtended/Moderat-Extended-Thin.woff2") format("woff2"),
    url("./fontfiles/ModeratExtended/Moderat-Extended-Thin.woff") format("woff");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

// @each $family in Moderat,ModeratMono,ModeratCondensed,ModeratExtendend {
//   @font-face {
//     font-family: #{$family};
//     src:
//       url(".s/fontfiles/#{$family}/#{$family}.woff") format("woff"),
//       url(".s/fontfiles/#{$family}/#{$family}.woff2") format("woff2");
//       font-weight: normal;
//       font-style: normal;
//       font-display: swap;
//   }
// }
