@import "assets/scss/utils.scss";
@import "assets/scss/variables.scss";

.footer {
  background: #effffd;

  .content {
    @include dims(calc(100vw - ($frameWidth * 2) + 2px), 100%);
    border-left: 1px solid #b9ddd8;
    border-right: 1px solid #b9ddd8;
    margin: 0 auto;
    padding-top: 3vw;

    @include devicesMax(tablet) {
      @include dims(100%);
      border-left: none;
      border-right: none;
    }

    @include devicesMax(mobile) {
      padding-top: 10vw;
    }

    .top {
      border-top: 1px solid #b9ddd8;
      padding: 4vw 2.5vw 2vw 2.5vw;

      display: grid;
      grid-template-columns: repeat(2, 1fr);

      @include devicesMax(mobile) {
        grid-template-columns: repeat(1, auto);
        grid-template-rows: repeat(2, auto);
        padding: 15vw 2.5vw 2vw 2.5vw;
      }

      .reserve {
        @include devicesMax(mobile) {
          border-bottom: 1px solid #b9ddd8;
          padding-bottom: 10vw;
        }

        .title {
          color: #282828;
          font-family: ModeratExtended;
          font-weight: 500;
          font-size: 3vw;
          line-height: 120%;
          width: 65%;

          @include devicesMax(mobile) {
            font-size: 10vw;
            line-height: 130%;
            margin: 0 auto 15vw auto;
            text-align: center;
            width: 85%;
          }

          br {
            @include devicesMax(mobile) {
              display: none;
            }
          }
        }

        .btnW {
          @include dims(11vw);
          display: block;
          margin-bottom: 3vw;
          transform: translate(12vw, -2vw);

          @include devicesMax(tablet) {
            @include dims(14vw);
          }

          @include devicesMax(mobile) {
            @include dims(45vw);
            margin: 0 auto 20vw auto;
            transform: translate(0, 0);
          }
        }

        .links {
          display: flex;
          align-items: flex-end;

          @include devicesMax(mobile) {
            align-items: center;
            flex-direction: column;
            justify-content: center;
          }

          .linksW {
            display: flex;
            flex-direction: column;

            &:nth-child(2) {
              margin-left: 3vw;

              @include devicesMax(mobile) {
                margin-left: 0;
              }
            }

            .link {
              color: #282828;
              display: inline-block;
              font-family: Moderat;
              font-weight: 400;
              font-size: 1.15vw;
              line-height: 100%;
              margin-bottom: 1vw;

              @include devicesMax(mobile) {
                font-size: 5vw;
                margin: 0 auto 5vw auto;
                text-align: center;
              }
            }
          }
        }
      }

      .sayHi {
        display: flex;
        align-items: flex-start;

        @include devicesMax(mobile) {
          align-items: center;
          flex-direction: column;
          justify-content: center;
          padding-top: 10vw;
        }

        .imgW {
          @include dims(13vw);
          flex-shrink: 0;

          @include devicesMax(mobile) {
            @include dims(40vw);
            margin: 5vw auto 5vw auto;
          }
        }

        .contactInfoW {
          @include dims(auto, 100%);
          display: flex;
          flex-direction: column;
          margin-left: 2vw;

          @include devicesMax(mobile) {
            @include dims(100%);
            margin-left: 0;
          }

          .title {
            color: #282828;
            font-family: ModeratExtended;
            font-weight: 500;
            font-size: 3.5vw;
            line-height: 100%;
            margin-bottom: 2vw;

            @include devicesMax(mobile) {
              font-size: 20vw;
              margin: 0 auto 15vw auto;
            }
          }

          .contactInfo {
            .part {
              &:nth-child(1) {
                display: flex;
                align-items: center;
                justify-content: space-between;

                @include devicesMax(mobile) {
                  flex-direction: column;
                  justify-content: center;
                }
              }

              .itemW {
                $paddingLeft: 1.5vw;
                display: flex;
                flex-direction: column;
                margin-bottom: 2vw;

                @include devicesMax(mobile) {
                  align-items: center;
                  margin-bottom: 10vw;
                  justify-content: center;
                }

                .label {
                  color: #b9ddd8;
                  font-family: Moderat;
                  font-weight: 500;
                  font-size: 0.75rem;
                  line-height: 100%;
                  margin-bottom: 0.25vw;
                  padding-left: $paddingLeft;

                  @include devicesMax(mobile) {
                    font-size: 0.75rem;
                    margin-bottom: 2vw;
                    padding-left: 0;
                  }
                }

                .item {
                  border: 1px solid #b9ddd8;
                  border-radius: 64px;
                  color: #282828;
                  font-family: Moderat;
                  font-weight: 400;
                  font-size: 1.25vw;
                  line-height: 100%;
                  padding: 0.75vw $paddingLeft;

                  @include devicesMax(mobile) {
                    font-size: 1.25rem;
                    padding: 0.75rem 1.5rem;
                  }

                  &.address {
                    border-radius: 16px;
                    line-height: 140%;
                    padding: 1.5vw $paddingLeft;
                    display: flex;
                    flex-direction: column;

                    @include devicesMax(mobile) {
                      line-height: 160%;
                      text-align: center;
                      padding: 5vw 0;
                    }
                  }

                  .btnGetDirection {
                    border: 1px solid #282828;
                    border-radius: 64px;
                    font-size: 1vw;
                    margin-top: 2vw;
                    margin-right: auto;
                    padding: 0.1rem 1.25rem;

                    display: flex;
                    align-items: center;

                    @include devicesMax(mobile) {
                      font-size: 4vw;
                      margin: 10vw auto 5vw auto;
                    }

                    .iconW {
                      @include dims(0.75rem, auto);
                      margin-left: 0.5rem;
                      display: flex;
                      align-items: center;

                      @include devicesMax(tablet) {
                        @include dims(0.75vw, auto);
                      }

                      @include devicesMax(mobile) {
                        @include dims(3vw, auto);
                      }
                    }
                  }
                }
              }
            }
          }

          .social {
            margin-top: auto;
            margin-left: auto;

            @include devicesMax(mobile) {
              margin: 5vw auto;
            }

            .link {
              @extend .cursorPointer;

              &:nth-child(2) {
                margin-left: 1.5vw;

                @include devicesMax(mobile) {
                  margin-left: 5vw;
                }
              }
            }
          }
        }
      }
    }

    .copyright {
      border-top: 1px solid #b9ddd8;
      display: flex;
      padding: 1.25vw 2.5vw 2vw 2.5vw;

      @include devicesMax(tablet) {
        padding: 1.25vw 2.5vw 4vw 2.5vw;
      }

      @include devicesMax(mobile) {
        @include dims(90%, 100%);
        flex-direction: column;
        margin: 0 auto;
        padding: 5vw 0;
      }

      .item {
        color: #282828;
        display: inline-block;
        font-family: Moderat;
        font-weight: 400;
        font-size: 1vw;
        line-height: 100%;

        @include devicesMax(mobile) {
          text-align: center;
          margin-bottom: 5vw;
        }

        &.rights {
          @include devicesMax(mobile) {
            margin-bottom: 10vw;
            order: 1;
          }
        }

        &.signature {
          margin-left: auto;

          @include devicesMax(mobile) {
            margin-left: 0;
            order: 2;
          }

          .link {
            text-decoration: underline;
          }
        }

        &.privacy {
          margin-left: 7vw;
          order: 0;
          text-decoration: underline;

          @include devicesMax(mobile) {
            margin-left: 0;
            opacity: 0.5;
          }
        }

        @include devicesMax(mobile) {
          font-size: 4vw;
        }
      }
    }
  }
}
