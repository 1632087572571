@import "./utils.scss";

.reservation {
  @include dims(100%, calc(100vh - $headerHeight));
  padding: 0 calc($frameWidth);
  position: relative;
  @extend .horizontalBorders;
  display: grid;
  grid-template-columns: 45vw auto;

  @include devicesMax(tablet) {
    @include dims(100vw, auto);
    padding: 0 4vw;
    display: flex;
    flex-direction: column;
  }

  @include devicesMax(tablet) {
    padding: 0 6vw;
  }

  .services {
    padding: 4vw 5vw;
    position: relative;

    @include devicesMax(mobile) {
      padding: 4vw 0;
    }

    .title {
      @include dims(70%, auto);
      color: #282828;
      font-family: ModeratExtended;
      font-size: 2vw;
      font-weight: 500;
      line-height: 120%;
      margin-bottom: 2vw;

      @include devicesMax(tablet) {
        @include dims(90%, auto);
        font-size: 5.5vw;
        line-height: 160%;
        margin: 5vw auto;
        text-align: center;
      }

      @include devicesMax(mobile) {
        @include dims(100%, auto);
        font-size: 7.5vw;
      }
    }

    .imgW {
      @include dims(100%, 15vw);
      margin-bottom: 3vw;

      @include devicesMax(tablet) {
        @include dims(100%, 30vw);
        margin-bottom: 4vw;
      }

      @include devicesMax(mobile) {
        @include dims(100%, 40vw);
        margin-bottom: 10vw;
      }
    }

    .servicesList {
      @include dims(100%, 12vw);
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      list-style: none;

      @include devicesMax(tablet) {
        @include dims(100%, 30vw);
      }

      @include devicesMax(mobile) {
        @include dims(100%, auto);
      }

      .itemText {
        color: #282828;
        display: list-item;
        font-family: Moderat;
        font-size: 1vw;
        font-weight: 400;
        line-height: 100%;
        margin-bottom: 1vw;

        @include devicesMax(tablet) {
          font-size: 2.5vw;
          margin-bottom: 2.5vw;
        }

        @include devicesMax(mobile) {
          font-size: 4.5vw;
          margin-bottom: 5vw;
        }

        &::before {
          content: "\2022"; /* Add content: \2022 is the CSS Code/unicode for a bullet */
          color: #dcdcff; /* Change the color */
          font-weight: bold; /* If you want it to be bold */
          display: inline-block; /* Needed to add space between the bullet and the text */
          width: 1em; /* Also needed for space (tweak if needed) */
          transform: scale(2);
        }
      }
    }
  }

  .formW {
    position: relative;

    .form {
      $fontSize: 1.25rem;
      $fontSizeMobile: 1rem;
      $paddingLeft: 1.25rem;
      $inputheight: 3.5vw;
      $marginBottom: 2.25vw;
      $havePetWidth: 8vw;
      $havePetWidthTablet: 14vw;
      $havePetWidthMobile: 32vw;
      @include dims(100%, auto);
      display: flex;
      flex-direction: column;
      padding: 7vw;
      position: relative;

      @include devicesMax(tablet) {
        @include dims(60vw, auto);
        padding: 0;
        margin: 10vw auto;
      }

      @include devicesMax(mobile) {
        @include dims(100%, auto);
        margin: 20vw auto;
      }

      &:after {
        content: "";
        @include position(absolute, 0, auto, 0, 0);
        @include dims(1px, 80%);
        background: #adadad;

        @include devicesMax(tablet) {
          display: none;
        }
      }

      .mB {
        margin-bottom: calc($marginBottom * 2.5);

        @include devicesMax(mobile) {
          margin-bottom: calc($marginBottom * 8);
        }
      }

      .mT {
        margin-top: calc($marginBottom * 2.5);

        @include devicesMax(mobile) {
          margin-top: calc($marginBottom * 8);
        }
      }

      .error {
        @include position(absolute, 100%, auto, auto, auto);
        color: #3b3b3b;
        font-family: Moderat;
        font-size: 1rem;
        font-weight: 400;
        line-height: 160%;
        margin: 2vw 0;
      }

      .reservationInfo {
        color: #3b3b3b;
        font-family: Moderat;
        font-size: 1vw;
        font-weight: 400;
        line-height: 200%;
        margin-bottom: 5vw;
        // white-space: nowrap;

        @include devicesMax(tablet) {
          font-size: 1.5vw;
          text-align: center;
        }

        @include devicesMax(mobile) {
          font-size: 4vw;
        }

        strong {
          border-radius: 64px;
          // border: 1px solid #adadad;
          background: #9df98a;
          font-family: Moderat;
          font-size: 1.25vw;
          font-weight: 500;
          margin: 0 0.5rem;
          padding: 0.35rem 0.5rem;

          @include devicesMax(tablet) {
            font-size: 1.75vw;
          }

          @include devicesMax(mobile) {
            font-size: 4.5vw;
          }
        }
      }

      .row {
        display: flex;
        gap: 1rem;

        @include devicesMax(mobile) {
          flex-direction: column;
          gap: 0;
        }

        &.havePet {
          margin-top: 4vw;

          @include devicesMax(tablet) {
            margin-top: 8vw;
          }

          @include devicesMax(mobile) {
            margin-top: 20vw;
          }

          .smallW {
            width: $havePetWidth;

            @include devicesMax(tablet) {
              width: $havePetWidthTablet;
            }

            @include devicesMax(mobile) {
              width: $havePetWidthMobile;
              margin-bottom: 0;
            }
          }
        }

        &.flightNo {
          .inputW {
            width: 50%;

            @include devicesMax(mobile) {
              width: 100%;
            }
          }
        }

        &.petInfo {
          .smallW {
            @include devicesMax(mobile) {
              margin-bottom: 3vw;
            }
          }
        }
      }

      .selectServiceW {
        margin-top: 7vw;

        .notes {
          display: flex;
          flex-direction: column;

          .note {
            color: #bebebe;
            font-family: Moderat;
            font-size: 1rem;
            font-weight: 400;
            line-height: 120%;
            margin-bottom: 1vw;
            padding-left: $paddingLeft;

            @include devicesMax(mobile) {
              font-size: 13px;
              margin-bottom: 3vw;
              padding-left: 0;
            }
          }
        }
      }

      .petInfoW {
        max-height: 0;
        transition: 1s all ease;
        overflow: hidden;

        @include devicesMax(mobile) {
          padding-right: 7vw;
          margin-bottom: 0;
        }

        .row {
          &.petInfo {
            @extend .hide;
            position: relative;
            transition: 0.4s all ease;

            @include devicesMax(mobile) {
              gap: 0;
              margin: 7.5vw 0;

              .inputW {
                margin-bottom: 3vw;
              }
            }

            .deleteField {
              @include position(absolute, 50%, auto, auto, 100%);
              @include dims(1.25vw);
              transform: translateY(-160%);
              margin-left: 2vw;
              @extend .cursorPointer;

              @include devicesMax(tablet) {
                @include dims(1.5vw);
              }

              @include devicesMax(mobile) {
                @include dims(5vw);
                transform: translateY(-280%) translateX(20%);
              }
            }
          }
        }

        &.visible {
          max-height: 100vh;
          overflow: visible;

          .row {
            &.petInfo {
              @extend .show;
            }
          }
        }
      }

      .addButton {
        border: 1px solid #adadad;
        border-radius: 32px;
        margin-bottom: 2vw;
        margin-right: auto;
        padding: 0.75rem;
        position: relative;
        @extend .cursorPointer;

        display: flex;
        align-items: center;
        justify-content: center;

        @include devicesMax(mobile) {
          padding: 0.5rem;
        }

        .plus {
          @include dims(1.85vw);
          background: #dcdcff;
          font-weight: 400;
          font-size: 150%;
          border-radius: 50%;
          margin-right: 0.5rem;
          @extend .flexcenter;
          @extend .cursorPointer;

          &:last-child {
            margin-right: 0;
          }

          @include devicesMax(tablet) {
            @include dims(2.5vw);
          }

          @include devicesMax(mobile) {
            @include dims(8.5vw);
          }

          .actionIconW {
            display: block;
            @include dims(45%);
            object-fit: contain;

            .actionIcon {
              @include dims(100%);
              display: block;
              object-fit: contain;
            }
          }
        }

        // .plus {
        //   @include dims(1.75vw);
        //   background: #dcdcff;
        //   font-weight: 500;
        //   font-size: 1.35rem;
        //   border-radius: 50%;
        //   margin-right: 0.5rem;

        //   @include devicesMax(tablet) {
        //     @include dims(2.5vw);
        //   }

        //   @include devicesMax(mobile) {
        //     @include dims(7.25vw);
        //   }

        //   &:last-child {
        //     margin-right: 0;
        //   }
        // }

        .btnText {
          color: #3b3b3b;
          font-family: Moderat;
          font-size: 1vw;
          font-weight: 400;
          line-height: 100%;

          @include devicesMax(tablet) {
            font-size: 1.75vw;
          }

          @include devicesMax(mobile) {
            font-size: 3.75vw;
          }
        }
      }

      .datePickerW {
        @include dims(100%, 3.5vw);
        border: 1px solid #adadad;
        border-radius: 32px;
        position: relative;
        margin-bottom: 2vw;

        .label {
          @include position(absolute, 0, 0, 0, 0);
          @extend .flexstartH;
          color: #282828;
          font-family: Moderat;
          font-size: $fontSize;
          font-weight: 400;
          line-height: 120%;
          opacity: 1;
          padding-left: $paddingLeft;
          transition: 0.4s all ease;
          pointer-events: none;
          z-index: 10;

          &.hidden {
            transition: 0s;
            @extend .hide;
          }
        }
      }

      .textareaW {
        @include dims(100%, auto);
        border-bottom: 1px solid #adadad;
        margin-bottom: 1.5vw;
        margin-top: 1.5vw;

        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;

        @include devicesMax(tablet) {
          @include dims(100%, 14vw);
          margin-bottom: 3vw;
        }

        @include devicesMax(mobile) {
          @include dims(100%, 35vw);
          margin-bottom: 6vw;
          margin-top: 5vw;
        }

        .textareaLabel {
          @include dims(100%, auto);
          @extend .flexstartH;
          color: #3b3b3b;
          font-family: Moderat;
          font-size: $fontSize;
          font-weight: 400;
          line-height: 120%;
          margin-bottom: 0.5vw;
          padding-left: $paddingLeft;

          @include devicesMax(mobile) {
            font-size: $fontSizeMobile;
            margin-bottom: 2vw;
          }
        }

        .textarea {
          @include dims(100%, 10vw);
          color: #3b3b3b;
          font-family: Moderat;
          font-size: 1.25rem;
          font-weight: 400;
          line-height: 120%;
          margin: 0;
          padding: 0;
          background: none;
          border: none;
          outline: none;
          resize: none;

          @include devicesMax(tablet) {
            @include dims(100%, 10vw);
          }

          @include devicesMax(mobile) {
            @include dims(100%, 30vw);
            font-size: $fontSizeMobile;
          }
        }
      }

      .smallW {
        @include dims(100%, $inputheight);
        border: 1px solid #adadad;
        border-radius: 32px;
        margin-bottom: $marginBottom;
        position: relative;

        @include devicesMax(tablet) {
          @include dims(100%, 6vw);
        }

        @include devicesMax(mobile) {
          @include dims(100%, 14vw);
          margin-bottom: 7.5vw;
        }

        .smallTop,
        .smallBottom {
          @include position(absolute, auto, auto, 120%, auto);
          color: #bebebe;
          font-family: Moderat;
          font-size: 1rem;
          font-weight: 400;
          line-height: 120%;
          padding-left: $paddingLeft;
          white-space: nowrap;

          &.dark {
            color: #282828;
          }
        }

        .smallBottom {
          @include position(absolute, 110%, auto, auto, auto);

          @include devicesMax(mobile) {
            font-size: 11px;
          }
        }

        .smallTop {
          font-size: 1.1rem;

          @include devicesMax(mobile) {
            @include position(absolute, auto, auto, 110%, 50%);
            font-size: 12px;
            margin-bottom: 2vw;
            padding-left: 0;
            transform: translateX(-50%);
          }
        }
      }

      .inputW {
        @include dims(100%);
        border-radius: 32px;

        &.oHidden {
          overflow: hidden;
        }

        .antPicker {
          @include dims(100%);
          @extend .cursorPointer;

          // border-radius: 32px;
          // font-family: Moderat;
          // font-size: 1.5vw;
          // color: #282828;

          // .ant-picker-input {
          //   input {
          //     color: #282828;
          //   }

          //   input::placeholder {
          //     color: #282828;
          //   }
          // }

          // .ant-btn-primary {
          //   background: red !important;
          // }

          // svg {
          //   path {
          //     fill: #282828;
          //   }
          // }
        }
      }

      .label {
        @include position(absolute, 0, 0, 0, 0);
        @extend .flexstartH;
        color: #282828;
        font-family: Moderat;
        font-size: $fontSize;
        font-weight: 400;
        line-height: 120%;
        opacity: 1;
        padding-left: $paddingLeft;
        transition: 0.4s all ease;

        &.hidden {
          transition: 0s;
          @extend .hide;
        }

        @include devicesMax(mobile) {
          font-size: $fontSizeMobile;
        }
      }

      .input {
        @include position(absolute, 0, 0, 0, 0);
        @extend .flexstartH;
        border-radius: 32px;
        color: #282828;
        font-family: Moderat;
        font-size: $fontSize;
        font-weight: 400;
        line-height: 120%;
        margin: 0;
        padding: 0;
        padding-left: $paddingLeft;
        background: none;
        border: none;
        outline: none;

        @include devicesMax(mobile) {
          font-size: $fontSizeMobile;
        }
      }

      .checkboxInput {
        $transition: 0.2s transform ease;
        @include dims($havePetWidth, 100%);
        border-radius: 32px;
        position: relative;
        overflow: hidden;
        z-index: 20;
        @extend .cursorPointer;

        @include devicesMax(tablet) {
          @include dims($havePetWidthTablet, 100%);
        }

        @include devicesMax(mobile) {
          @include dims($havePetWidthMobile, 100%);
        }

        &.checked {
          .btnW {
            .yes {
              transform: translateX(-100%);
            }

            .no {
              transform: translateX(-100%);
            }
          }
        }

        .btnW {
          @include dims(calc($havePetWidth * 2), 100%);
          display: flex;
          align-items: center;
          justify-content: flex-start;

          @include devicesMax(tablet) {
            @include dims(calc($havePetWidthTablet * 2), 100%);
          }

          @include devicesMax(mobile) {
            @include dims(calc($havePetWidthMobile * 2), 100%);
          }
        }

        .circle {
          @include dims(2.5vw);
          background: #efefff;
          border-radius: 50%;

          @include devicesMax(tablet) {
            @include dims(4.5vw);
          }

          @include devicesMax(mobile) {
            @include dims(10vw);
          }
        }

        .movin {
          @include dims($havePetWidth, 100%);
          border-radius: 32px;
          padding: 0 0.5rem;
          transition: $transition;

          @include devicesMax(tablet) {
            @include dims($havePetWidthTablet, 100%);
          }

          @include devicesMax(mobile) {
            @include dims($havePetWidthMobile, 100%);
          }

          .movinText {
            color: #282828;
            font-family: Moderat;
            font-size: $fontSize;
            font-weight: 400;
            line-height: 100%;
            padding: 0 0.5rem;

            @include devicesMax(mobile) {
              font-size: $fontSizeMobile;
            }
          }
        }

        .yes {
          background: #dcdcff;
          transform: translateX(0);
          z-index: 2;

          display: flex;
          align-items: center;
          justify-content: space-between;
        }

        .no {
          transform: translateX(-25%);
          z-index: 1;

          display: flex;
          align-items: center;
          justify-content: space-between;
        }
      }

      .confirmation {
        $boxDims: 20px;
        @extend .cursorPointer;

        display: flex;
        align-items: flex-start;
        justify-content: flex-start;

        .checkbox {
          @include dims($boxDims);
          border: 1px solid #282828;
          border-radius: 4px;
          flex-shrink: 0;
          margin-top: 0.25vw;
          position: relative;

          .inner {
            @extend .abscenter;
            @include dims(calc($boxDims - 6px));
            background: #282828;
            border-radius: 2px;
            transition: 0.3s all ease;
            @extend .hide;

            &.enabled {
              @extend .show;
            }
          }
        }

        .text {
          margin-left: 0.5rem;
          width: 90%;

          @include devicesMax(tablet) {
            margin-left: 1rem;
            width: 100%;
          }

          @include devicesMax(mobile) {
            margin-left: 1rem;
            width: 100%;
          }

          .small,
          .link {
            color: #3b3b3b;
            display: inline;
            font-family: Moderat;
            font-size: 1vw;
            font-weight: 400;
            line-height: 160%;

            @include devicesMax(tablet) {
              font-size: 1.25vw;
            }

            @include devicesMax(mobile) {
              font-size: 3.25vw;
            }
          }

          .link {
            display: inline-block;
            text-decoration: underline;
          }
        }
      }

      .sendBtn {
        @include dims(18vw, 3.75vw);
        border: 1px solid #282828;
        border-radius: 32px;
        color: #282828;
        font-family: Moderat;
        font-size: 1.5vw;
        font-weight: 400;
        line-height: 100%;
        margin: 4vw auto 0 auto;
        opacity: 0.3;
        pointer-events: none;
        transition: 0.4s all ease;
        transform-origin: center;
        @extend .cursorPointer;

        @include devicesMin(aboveTablet) {
          &:hover {
            background: #fef8a6;
            transform: rotate(-9deg);
          }
        }

        @include devicesMax(tablet) {
          @include dims(22vw, 5vw);
          font-size: 1.75vw;
          margin: 8vw auto 0 auto;
        }

        @include devicesMax(mobile) {
          @include dims(60vw, 12vw);
          font-size: 4.5vw;
          margin: 12vw auto 0 auto;
        }

        &.enabled {
          opacity: 1;
          pointer-events: auto;
        }
      }
    }
  }
}
