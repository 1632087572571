@import "assets/scss/utils.scss";

// .marquee {
//   margin: 2rem 0;
//   position: relative;
//   overflow: hidden;

//   .marqueeInner {
//     @include dims(100%);
//     display: flex;
//     will-change: transform;
//     width: fit-content;
//     display: flex;
//     flex: auto;
//     flex-direction: row;

//     .marqueeItem {
//       display: inline-block;
//       flex-shrink: 0;
//       padding: 0 4px;
//     }
//   }
// }

.marquee {
  margin: 2rem 0;

  .marqueeInner {
    display: flex;
    will-change: transform;
  }
}
