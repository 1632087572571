@import "./utils.scss";

.selectInput {
  $fontSize: 2vw;
  $paddingLeft: 1rem;
  @include dims(100%);
  position: relative;
  @extend .cursorPointer;

  .label {
    @include position(absolute, 0, 0, 0, 0);
    @extend .flexstartH;
    color: #282828;
    font-family: Moderat;
    font-size: 1.25rem;
    font-weight: 400;
    line-height: 120%;
    opacity: 1;
    padding-left: $paddingLeft;
    transition: 0.4s all ease;
    pointer-events: none;

    &.hidden {
      transition: 0s;
      @extend .hide;
    }

    @include devicesMax(mobile) {
      font-size: 1rem;
    }
  }

  .iconW {
    @include position(absolute, 50%, $paddingLeft, auto, auto);
    @include dims(1.75vw, auto);
    transform: translateY(-50%);

    .t {
      transform: rotate(45deg);
    }

    @include devicesMax(mobile) {
      @include dims(5vw, auto);
    }
  }

  .options {
    @include position(absolute, 120%, 0, auto, 0);
    @include dims(100%, auto);
    transition: 0.4s all ease;
    background: #fff;
    border: 1px solid #adadadd7;
    border-radius: 14px;
    overflow: hidden;
    z-index: 100;

    .option {
      color: #282828;
      font-family: Moderat;
      font-size: 1.25rem;
      font-weight: 400;
      line-height: 120%;
      opacity: 1;
      padding: 1rem $paddingLeft;
      position: relative;
      transition: 0.4s all ease;

      @include devicesMax(mobile) {
        font-size: 1rem;
      }

      &:hover {
        background: #f6f6f6;
      }

      &:not(:last-child) {
        &::after {
          content: "";
          @include position(absolute, auto, 0, 0, 50%);
          @include dims(95vw, 1px);
          background: #adadad42;
          transform: translateX(-50%);
          z-index: -1;
        }
      }
    }
  }
}
