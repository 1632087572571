@import "assets/scss/normalize.scss";
@import "assets/scss/reset.scss";
@import "assets/font/fonts.scss";
@import "assets/scss/utils.scss";

// html {
//   @media screen and (min-width: 1025px) {
//     body {
//       height: 100%;
//       width: 100%;
//       overflow: hidden;
//     }

//     [data-scroller] {
//       height: 100vh;
//     }

.scrollbar-track {
  background: transparent !important;
}
//   }
// }

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

* {
  cursor: default;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
small,
label,
a,
span,
strong {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

main {
  position: relative;
  overflow: hidden;
  z-index: z-index("main");

  @include devicesMax(tablet) {
    margin-top: $headerHeightTablet;
  }

  @include devicesMax(mobile) {
    margin-top: $headerHeightMobile;
  }
}

.ant-picker-footer {
  display: none;
}

.ant-picker-dropdown {
  z-index: z-index("antdesign");
}
