@import "assets/scss/utils.scss";
@import "assets/scss/variables.scss";

.infiniteBrandDetail {
  .currentBrand,
  .nextBrand {
    padding-left: 15vw;

    @include devicesMax(mobile) {
      padding-left: 0;
    }
  }

  .currentBrand {
    z-index: 10;
    position: relative;
  }

  .oWrapper {
    z-index: 100;
    position: relative;
  }

  // .currentBrand {
  //   transform: translateY(1px);
  // }
}
