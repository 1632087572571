@import "../scss/utils.scss";

.img {
  @include dims(100%);
  position: relative;
  display: block;
  z-index: z-index("img");
  @extend .hide;

  &.visible {
    @extend .show;
  }
}
