@import "assets/scss/utils.scss";
@import "assets/scss/variables.scss";

.modal {
  @include position(fixed, 0, 0, 0, 0);
  @include dims(100vw, 100vh);
  overflow: hidden;
  z-index: z-index("modal");

  .close {
    @include position(absolute, 2vw, 3vw, auto, auto);
    display: inline-block;
    z-index: z-index("modalClose");
    @extend .cursorPointer;

    @include devicesMax(tablet) {
      @include position(absolute, 4vw, 4vw, auto, auto);
    }

    @include devicesMax(mobile) {
      @include position(absolute, 5vw, 5vw, auto, auto);
      @include dims(5vw, auto);
    }
  }

  .content {
    @include dims(100%);
    position: relative;
    z-index: z-index("modalContent");

    .contentInner {
      @include dims(100%);
      overflow: auto;
      z-index: z-index("contentInner");

      .aside {
        @include dims(75%, auto);
        margin-left: auto;

        @include devicesMax(tablet) {
          @include dims(100vw, auto);
        }
      }

      .box {
        @include dims(100%, 150vh);

        &:nth-child(1) {
          background: red;
        }

        &:nth-child(2) {
          background: blue;
        }

        &:nth-child(3) {
          background: greenyellow;
        }

        &:nth-child(4) {
          background: orangered;
        }
      }
    }
  }

  .backdrop {
    @include position(fixed, 0, 0, 0, 0);
    @include dims(100%);

    background: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(8px);
    -webkit-backdrop-filter: blur(8px);
  }
}
