@import "./utils.scss";

.counterInput {
  @include dims(100%);
  $fontSize: 2vw;
  $paddingLeft: 1rem;

  display: flex;
  align-items: center;
  justify-content: flex-end;

  .label {
    @include position(absolute, 0, 0, 0, 0);
    @extend .flexstartH;
    color: #282828;
    font-family: Moderat;
    font-size: 1.25rem;
    font-weight: 400;
    line-height: 120%;
    opacity: 1;
    padding-left: $paddingLeft;
    transition: 0.4s all ease;
    pointer-events: none;

    &.hidden {
      transition: 0s;
      @extend .hide;
    }

    @include devicesMax(mobile) {
      font-size: 1rem;
    }
  }

  .input {
    @include dims(100%);
    @extend .flexstartH;
    color: #282828;
    display: inline-block;
    flex: 1;
    font-family: Moderat;
    font-size: 2.5rem;
    font-weight: 400;
    line-height: 100%;
    margin: 0;
    padding: 0;
    padding-left: 55%;
    padding-bottom: 0.35rem;
    background: none;
    border: none;
    outline: none;

    /* Chrome, Safari, Edge, Opera */
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    &[type="number"] {
      -moz-appearance: textfield;
    }

    @include devicesMax(mobile) {
      font-size: 9vw;
    }
  }

  .iconW {
    @include position(absolute, 50%, 0, auto, auto);
    @include dims(2vw, auto);
    transform: translateY(-50%);
  }

  .actionW {
    padding-right: calc($paddingLeft / 1.5);
    z-index: 100;

    display: flex;
    align-items: center;
    justify-content: center;

    .count {
      color: #282828;
      font-family: Moderat;
      font-size: 2rem;
      font-weight: 400;
      line-height: 100%;
    }

    .action {
      @include dims(1.85vw);
      background: #dcdcff;
      font-weight: 400;
      font-size: 150%;
      border-radius: 50%;
      margin-right: 0.5rem;
      opacity: 1;
      pointer-events: auto;
      @extend .flexcenter;
      @extend .cursorPointer;

      &:last-child {
        margin-right: 0;
      }

      @include devicesMax(tablet) {
        @include dims(2.5vw);
      }

      @include devicesMax(mobile) {
        @include dims(8.5vw);
      }

      &.disabled {
        opacity: 0.2;
        pointer-events: none;
      }

      .actionIconW {
        display: block;
        @include dims(45%);
        object-fit: contain;

        .actionIcon {
          @include dims(100%);
          display: block;
          object-fit: contain;
        }
      }
    }
  }
}
