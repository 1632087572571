@import "./variables.scss";

@mixin dims($width, $height: $width) {
  width: $width;
  height: $height;
}

@mixin font-size($font-size, $line-height: normal, $letter-spacing: normal) {
  font-size: $font-size * 1px;
  // font-size: $font-size * 0.1rem;
  // example using rem values and 62.5% font-size so 1rem = 10px

  @if $line-height==normal {
    line-height: normal;
  } @else {
    line-height: $line-height / $font-size;
  }

  @if $letter-spacing==normal {
    letter-spacing: normal;
  } @else {
    letter-spacing: #{$letter-spacing / $font-size}em;
  }
}

@mixin position($position, $top: auto, $right: auto, $bottom: auto, $left: auto) {
  position: $position;
  top: $top;
  right: $right;
  bottom: $bottom;
  left: $left;
}

@mixin devicesMax($breakpoint) {
  @if $breakpoint == desktop {
    @media only screen and (max-width: 1440px) {
      @content;
    }
  }

  @if $breakpoint == tablet {
    @media only screen and (max-width: 1024px) {
      @content;
    }
  }

  @if $breakpoint == mobile {
    @media only screen and (max-width: 640px) {
      @content;
    }
  }
}

@mixin devicesMin($breakpoint) {
  @if $breakpoint == desktop {
    @media only screen and (min-width: 1680px) {
      @content;
    }
  }

  @if $breakpoint == aboveTablet {
    @media only screen and (min-width: 1025px) {
      @content;
    }
  }

  @if $breakpoint == tablet {
    @media only screen and (min-width: 1024px) {
      @content;
    }
  }

  @if $breakpoint == mobile {
    @media only screen and (min-width: 640px) {
      @content;
    }
  }
}

.hide {
  visibility: hidden;
  opacity: 0;
}

.show {
  visibility: visible;
  opacity: 1;
}

.cursorPointer {
  cursor: pointer;

  * {
    cursor: pointer;
  }
}

.abscenter {
  @include position(absolute, 50%, auto, auto, 50%);
  transform: translate(-50%, -50%);
}

.flexcenter {
  display: flex;
  align-items: center;
  justify-content: center;
}

.flexstartH {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.flexendH {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.flexstartV {
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

.flexendV {
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

// z-index
$z-indexes: (
  "preloader",
  "antdesign",
  "feedback",
  "modalClose",
  "contentInner",
  "modalContent",
  "modal",
  "logo",
  "hamburger",
  "lang",
  "header",
  "menu",
  "scrollbarTrack",
  "main",
  "img",
  "bg"
);

@function z-index($name) {
  @if index($z-indexes, $name) {
    @return ((length($z-indexes) - index($z-indexes, $name)) * 1000) + 100;
  } @else {
    @warn 'There is no item "#{$name}" in this list; choose one of: #{$z-indexes}';
    @return null;
  }
}

.horizontalBorders {
  position: relative;
  padding: 0 1.5;

  @include devicesMax(mobile) {
    padding: 0;
  }

  &:before {
    content: "";
    @include position(absolute, 0, auto, 0, 0);
    @include dims($frameWidth, 100%);
    border-right: 1px solid #adadad;
    z-index: -1;

    @include devicesMax(tablet) {
      display: none;
    }
  }

  &:after {
    content: "";
    @include position(absolute, 0, 0, 0, auto);
    @include dims($frameWidth, 100%);
    border-left: 1px solid #adadad;
    z-index: -1;

    @include devicesMax(tablet) {
      display: none;
    }
  }
}

.verticalBorderTop {
  position: relative;

  &::before {
    content: "";
    @include position(absolute, 0, 0, auto, 50%);
    @include dims(95vw, 1px);
    background: #adadad;
    transform: translateX(-50%);
    z-index: -1;
  }
}

.verticalBorderBottom {
  position: relative;

  &::after {
    content: "";
    @include position(absolute, auto, 0, 0, 50%);
    @include dims(95vw, 1px);
    background: #adadad;
    transform: translateX(-50%);
    z-index: -1;
  }
}
