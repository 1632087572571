@import "./utils.scss";

.exploreOurBrands {
  display: flex;

  @include devicesMax(mobile) {
    flex-direction: column;
  }

  .invitation {
    margin: 10vw 15vw 0 15vw;
    width: 40%;

    @include devicesMax(mobile) {
      margin: 15vw 0 5vw 0;
      width: 100%;
    }

    .desc {
      color: #282828;
      font-family: Moderat;
      font-weight: 500;
      font-size: 2.75vw;
      line-height: 140%;
      margin-bottom: 3vw;

      @include devicesMax(mobile) {
        font-size: 8.5vw;
        margin: 0 auto 10vw auto;
        text-align: center;
        width: 90%;
      }
    }

    .brandsW {
      display: flex;
      flex-wrap: wrap;

      @include devicesMax(mobile) {
        justify-content: center;
        width: 100%;
      }

      .brandW {
        display: block;
        margin: 1vw;
        transform-origin: center;

        &.chill {
          transform: translate(-5vw, 5vw) rotate(15deg);
          @include dims(9vw);

          @include devicesMax(mobile) {
            transform: translate(-5vw, 5vw) rotate(15deg);
            @include dims(35vw);
          }
        }

        &.snacknpack {
          transform: translate(-4vw, -3vw) rotate(-12deg);
          @include dims(11vw);

          @include devicesMax(mobile) {
            transform: translate(4vw, 0) rotate(-12deg);
            @include dims(35vw);
          }
        }

        &.mugginn {
          transform: translate(5vw, 3vw) rotate(-15deg);
          @include dims(7vw);

          @include devicesMax(mobile) {
            transform: translate(-2vw, 5vw) rotate(-15deg);
            @include dims(25vw);
          }
        }

        &.eatup {
          transform: translate(7vw, -2vw) rotate(15deg);
          @include dims(8vw);

          @include devicesMax(mobile) {
            transform: translate(0, 5vw) rotate(15deg);
            @include dims(30vw);
          }
        }

        &.minipack {
          transform: translate(8vw, -8vw) rotate(10deg);
          @include dims(10vw);

          @include devicesMax(mobile) {
            transform: translate(2vw, -5vw) rotate(10deg);
            @include dims(30vw);
          }
        }

        &.dlmLounge {
          transform: translate(10vw, -3vw) rotate(-10deg);
          @include dims(11vw);

          @include devicesMax(mobile) {
            transform: translate(2vw, 0) rotate(-10deg);
            @include dims(35vw);
          }
        }

        &.lagom {
          transform: translate(-5vw, -7vw) rotate(-10deg);
          @include dims(9vw);

          @include devicesMax(mobile) {
            transform: translate(5vw, 9vw) rotate(-10deg);
            @include dims(27vw);
          }
        }

        &.subway {
          transform: translate(5vw, -10vw) rotate(10deg);
          @include dims(11vw);

          @include devicesMax(mobile) {
            transform: translate(-2vw, -10vw) rotate(5deg);
            @include dims(30vw);
          }
        }

        &.simitSarayi {
          transform: translate(5vw, -5vw) rotate(-10deg);
          @include dims(8vw);

          @include devicesMax(mobile) {
            transform: translate(30vw, -5vw) rotate(-5deg);
            @include dims(25vw);
          }
        }
      }
    }
  }

  .btnW {
    @include dims(15vw);
    display: block;
    margin: auto auto auto 0;

    @include devicesMax(tablet) {
      @include dims(20vw);
    }

    @include devicesMax(mobile) {
      @include dims(47.5vw);
      margin: 0 auto 30vw auto;
    }
  }
}
